import "./keyBenefits.scss";
import { Box, Typography } from "@mui/material";
import correct from "../../../assets/images/mockInterview/correct-key.svg";

const KeyBenefits = () => {
  return (
    <Box padding="50px 10px">
      <Typography className="head-text-key">
        Key <span style={{ color: "black" }}>Benefits</span>
      </Typography>
      <Box className="wrap-key">
        <Box className="wrap-key-inside">
          <Box component="img" src={correct}></Box>
          <Typography className="norma-text-key">
            Enhance your interview confidence
          </Typography>
        </Box>
        <Box className="wrap-key-inside">
          <Box component="img" src={correct}></Box>
          <Typography className="norma-text-key">Get Hired Fast</Typography>
        </Box>
      </Box>
      <Box className="wrap-key">
        <Box className="wrap-key-inside">
          <Box component="img" src={correct}></Box>
          <Typography className="norma-text-key">
            Fast-track your career and increase your earnings.
          </Typography>
        </Box>
        <Box className="wrap-key-inside">
          <Box component="img" src={correct}></Box>
          <Typography className="norma-text-key">
            Achieve your dream job.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default KeyBenefits;
