
import React, { useState, useEffect } from 'react';
import "./animation.scss";
import { Box } from "@mui/material";
import AnimatedTypography from '../subComponents/AnimatedTypography';

const GaugeSpeedometer = () => {
    const [percentage, setPercentage] = useState(75);

    // Constants for the arc
    const radius = 35;
    const circumference = 2 * Math.PI * radius;

    // Calculate stroke offset based on percentage
    const strokeOffset = circumference - (percentage / 100) * circumference;

    return (
      <Box className="curve-container" position="relative">
        <svg viewBox="0 0 160 100" width="100%" height="100%">
          <defs>
            <linearGradient id="gaugeGradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" style={{ stopColor: '#7234f7' }} />
              <stop offset="100%" style={{ stopColor: '#7234f7' }} />
            </linearGradient>
          </defs>
          
          {/* Background semicircle */}
          <path
            className="gauge-background"
            d="M 25 80 A 35 35 0 0 1 135 80"
            stroke="#BD89FF !important"
            strokeWidth="10"
            fill="none"
             strokeLinecap="round"
          />
          
          {/* Animated fill */}
          <path
            className="gauge-fill"
            d="M 25 80 A 35 35 0 0 1 135 80"
            stroke="url(#gaugeGradient)"
            strokeWidth="10"
            fill="none"
            strokeDasharray={circumference}
            strokeDashoffset={strokeOffset}
            strokeLinecap="round"
          />
          
          {/* Percentage text */}
          <text 
            x="80" 
            y="85" 
            textAnchor="middle" 
            className="percentage-text" 
            style={{ fontSize: '24px', fill: '#7234f7' }}
          >
            <AnimatedTypography>{percentage}</AnimatedTypography>%
          </text>
        </svg>
      </Box>
    );
};

export default GaugeSpeedometer;
