// import React, { useState } from "react";
// import "./join.scss";
import Image1 from "../../../assets/images/mockInterview/1st-join.png";
import a1 from "../../../assets/images/mockInterview/a1.svg";
import a2 from "../../../assets/images/mockInterview/a2.svg";
import a3 from "../../../assets/images/mockInterview/a3.svg";
import a4 from "../../../assets/images/mockInterview/a4.svg";
import Image2 from "../../../assets/images/mockInterview/2nd-join.png";
// import { Box, Button, Typography, Dialog } from "@mui/material";
// import Avatar from "@mui/material/Avatar";
// import AvatarGroup from "@mui/material/AvatarGroup";
// import MockInterview from "./mockinterview/mockinterviewform";
// import Onetoone from "./mockinterview/onetoone";


import { useState } from "react";
import { Box, Typography, Avatar, AvatarGroup, Button, Dialog } from "@mui/material";
import MockInterview from "./mockinterview/mockinterviewform";
import Onetoone from "./mockinterview/onetoone";
import Groupcoaching from "./mockinterview/groupcoaching";
// import Image1 from "../assets/image1.png";
// import Image2 from "../assets/image2.png";
// import a1 from "../assets/a1.png";
// import a2 from "../assets/a2.png";
// import a3 from "../assets/a3.png";
// import a4 from "../assets/a4.png";

const Join = ({ targetJoinNow }) => {
  const [openDialog, setOpenDialog] = useState({ type: "", open: false });

  const handleOpenDialog = (type) => {
    setOpenDialog({ type, open: true });
  };

  const handleCloseDialog = () => {
    setOpenDialog({ type: "", open: false });
  };

  const sections = [
    {
      image: Image1,
      title: "1 : 1 Career Mentorship",
      description:
        "Provide personalized coaching for learners, guide them on skills, career paths, and job readiness, enhance your leadership and mentoring skills.",
      dialogComponent: <Onetoone handleCloseDialog={handleCloseDialog} />, 
    },
    {
      image: Image2,
      title: "Mock Interviews and Resume Reviews",
      description:
        "Help job seekers practice real-world interview scenarios, Provide actionable feedback to boost resume quality, Assist candidates in acing top tech & business roles.",
      dialogComponent: <MockInterview handleCloseDialog={handleCloseDialog} />, 
    },
    {
      image: Image1,
      title: "Host Group Coaching & Webinars",
      description:
        "Conduct live masterclasses & cohort-based training, Reach a wider audience and establish authority in your domain, Create a community of learners & professionals.",
      dialogComponent: <Groupcoaching handleCloseDialog={handleCloseDialog} />, 
    },
  ];

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#FFF",
          margin: "0 20px",
          position: "relative",
          zIndex: 2,
        }}
      >
        <Typography
          ref={targetJoinNow}
          style={{ scrollMarginTop: "60px" }}
          className="header-join"
        >
          <span style={{ color: "#FE7465" }}>Why Join</span> Coachvita?
        </Typography>

        {sections.map((section, index) => (
          <Box
            key={index}
            className="wrapper-1"
            sx={{
              alignItems: "center",
              borderRadius: "20px",
              border: "1px solid #EDEDED",
              display: "flex",
              padding: "24px",
              gap: "24px",
              justifyContent: "space-between",
              margin: { md: "5px 50px", xs: "5px 20px" },
            }}
          >
            {/* Image Box */}
            <Box
              className="img-join"
              sx={{ width: "326px", height: "200px" }}
              component="img"
              src={section.image}
            />

            {/* Content Box */}
            <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box
                  className="free"
                  sx={{
                    width: "117px",
                    padding: "5px 0",
                    fontFamily: "Outfit",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                    color: "black",
                    borderRadius: "20px",
                    border: "0.5px solid #000",
                    textAlign: "center",
                  }}
                >
                  Free
                </Box>

                {/* Avatar Group */}
                <AvatarGroup total={10} spacing="small" className="avg-G">
                  <Avatar alt="a1" src={a1} className="avg-i" />
                  <Avatar alt="a2" src={a2} className="avg-i" />
                  <Avatar alt="a3" src={a3} className="avg-i" />
                  <Avatar alt="a4" src={a4} className="avg-i" />
                </AvatarGroup>
              </Box>

              {/* Text Content */}
              <Box className="text-content">
                <Typography
                  fontFamily="Outfit"
                  fontSize="24px"
                  fontWeight="500"
                  lineHeight="normal"
                  paddingBottom="10px"
                  sx={{ paddingTop: "24px", fontSize: { xs: "18px !important" } }}
                >
                  {section.title}
                </Typography>
                <Typography
                  fontFamily="Poppins"
                  fontSize="14px"
                  fontStyle="normal"
                  lineHeight="normal"
                >
                  {section.description}
                </Typography>
              </Box>

              {/* Button */}
              <Box className="btn" sx={{ display: "flex", justifyContent: "end" }}>
                <Button
                  sx={{
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "normal",
                    color: "white",
                    fontFamily: "Outfit",
                    borderRadius: "8px",
                    background: "#7234F7",
                    padding: "10px 56px",
                    marginTop: "5px",
                    whiteSpace: "nowrap",
                    minWidth: "150px",
                    "&:hover": { background: "rgba(114, 52, 247)" },
                    textTransform: "none",
                  }}
                  onClick={() => handleOpenDialog(section.title)}
                >
                  Register Now
                </Button>
              </Box>
            </Box>
          </Box>
        ))}

        {/* Dialog Component */}
        <Dialog open={openDialog.open} onClose={handleCloseDialog}>
          {sections.find((sec) => sec.title === openDialog.type)?.dialogComponent}
        </Dialog>
        
      </Box>
    </>
  );
};

export default Join;

// const Join = ({ targetJoinNow }) => {
//   const [openMockInterview, setOpenMockInterview] = useState(false);

//   const handleOpenMockInterview = () => {
//     setOpenMockInterview(true);
//   };

//   const handleCloseMockInterview = () => {
//     setOpenMockInterview(false);
//   };

//   const [open_one_1_one, setopen_one_1_one] = useState(false);

//   const handleOpenone_1_one = () => {
//     setopen_one_1_one(true);
//   };

//   const handleCloseone_1_one = () => {
//     setopen_one_1_one(false);
//   };
//   return (
//     <>
//     <Box
//     sx={{
//       backgroundColor: "#FFF",
//       margin: "0 20px",
//       position: "relative",
//       zIndex: 2,
//     }}
//   >
//     <Typography
//       ref={targetJoinNow}
//       style={{ scrollMarginTop: "60px" }}
//       className="header-join"
//     >
//       <span style={{ color: "#FE7465" }}>Why Join</span> Coachvita?
//     </Typography>
  
//     <Box
//       className="wrapper-1"
//       sx={{
//         alignItems: "center",
//         borderRadius: "20px",
//         border: "1px solid #EDEDED",
//         display: "flex",
//         padding: "24px",
//         gap: "24px",
//         justifyContent: "space-between",
//         margin: { md: "5px 50px", xs: "5px 20px" },
//       }}
//     >
//       {/* Image Box */}
//       <Box
//         className="img-join"
//         sx={{ width: "326px", height: "200px" }}
//         component="img"
//         src={Image1}
//       />
  
//       {/* Content Box */}
//       <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
//         <Box
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-between",
//             width: "100%",
//           }}
//         >
//           <Box
//             className="free"
//             sx={{
//               width: "117px",
//               padding: "5px 0",
//               fontFamily: "Outfit",
//               fontStyle: "normal",
//               fontWeight: "400",
//               lineHeight: "normal",
//               color: "black",
//               borderRadius: "20px",
//               border: "0.5px solid #000",
//               textAlign: "center",
//             }}
//           >
//             Free
//           </Box>
  
//           {/* Avatar Group */}
//           <AvatarGroup total={10} spacing="small" className="avg-G">
//             <Avatar alt="a1" src={a1} className="avg-i" />
//             <Avatar alt="a2" src={a2} className="avg-i" />
//             <Avatar alt="a3" src={a3} className="avg-i" />
//             <Avatar alt="a4" src={a4} className="avg-i" />
//           </AvatarGroup>
//         </Box>
  
//         {/* Text Content */}
//         <Box className="text-content">
//           <Typography
//             fontFamily="Outfit"
//             fontSize="24px"
//             fontWeight="500"
//             lineHeight="normal"
//             paddingBottom="10px"
//             sx={{
//               paddingTop: "24px",
//               fontSize: { xs: "18px !important" },
//             }}
//           >
//             1 : 1 Career Mentorship
//           </Typography>
//           <Typography
//             fontFamily="Poppins"
//             fontSize="14px"
//             fontStyle="normal"
//             lineHeight="normal"
//           >
//             Provide personalized coaching for learners, guide them on skills, career paths, and job readiness,
//             enhance your leadership and mentoring skills.
//           </Typography>
//         </Box>
  
//         {/* Button */}
//         <Box className="btn" sx={{ display: "flex", justifyContent: "end" }}>
//           <Button
//             sx={{
//               fontSize: "16px",
//               fontStyle: "normal",
//               fontWeight: "600",
//               lineHeight: "normal",
//               color: "white",
//               fontFamily: "Outfit",
//               borderRadius: "8px",
//               background: "#7234F7",
//               padding: "10px 56px",
//               marginTop: "5px",
//               whiteSpace: "nowrap",
//               minWidth: "150px",
//               "&:hover": {
//                 background: "rgba(114, 52, 247)",
//               },
//               textTransform: "none",
//             }}
//             onClick={handleOpenone_1_one}
//           >
//             Register Now
//           </Button>
//         </Box>
//       </Box>
//     </Box>
  
//     {/* Dialog Component */}
//     <Dialog open={open_one_1_one} onClose={handleCloseone_1_one}>
//       <MockInterview
//         courseContent={{ course: "1 : 1 Career Mentorship" }}
//         handleCloseDialog={handleCloseone_1_one}
//       />
//     </Dialog>
//   </Box>

//   <Box
//     sx={{
//       backgroundColor: "#FFF",
//       margin: "0 20px",
//       position: "relative",
//       zIndex: 2,
//     }}
//   >
//     <Box
//       className="wrapper-1"
//       sx={{
//         alignItems: "center",
//         borderRadius: "20px",
//         border: "1px solid #EDEDED",
//         display: "flex",
//         padding: "24px",
//         gap: "24px",
//         justifyContent: "space-between",
//         margin: { md: "5px 50px", xs: "5px 20px" },
//       }}
//     >
//       {/* Image Box */}
//       <Box
//         className="img-join"
//         sx={{ width: "326px", height: "200px" }}
//         component="img"
//         src={Image2}
//       />
  
//       {/* Content Box */}
//       <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
//         <Box
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-between",
//             width: "100%",
//           }}
//         >
//           <Box
//             className="free"
//             sx={{
//               width: "117px",
//               padding: "5px 0",
//               fontFamily: "Outfit",
//               fontStyle: "normal",
//               fontWeight: "400",
//               lineHeight: "normal",
//               color: "black",
//               borderRadius: "20px",
//               border: "0.5px solid #000",
//               textAlign: "center",
//             }}
//           >
//             Free
//           </Box>
  
//           {/* Avatar Group */}
//           <AvatarGroup total={10} spacing="small" className="avg-G">
//             <Avatar alt="a1" src={a1} className="avg-i" />
//             <Avatar alt="a2" src={a2} className="avg-i" />
//             <Avatar alt="a3" src={a3} className="avg-i" />
//             <Avatar alt="a4" src={a4} className="avg-i" />
//           </AvatarGroup>
//         </Box>
  
//         {/* Text Content */}
//         <Box className="text-content">
//           <Typography
//             fontFamily="Outfit"
//             fontSize="24px"
//             fontWeight="500"
//             lineHeight="normal"
//             paddingBottom="10px"
//             sx={{
//               paddingTop: "24px",
//               fontSize: { xs: "18px !important" },
//             }}
//           >
//             Mock Interviews and Resume Reviews
//           </Typography>
//           <Typography
//             fontFamily="Poppins"
//             fontSize="14px"
//             fontStyle="normal"
//             lineHeight="normal"
//           >
//             Help job seekers practice real-world interview scenarios, Provide actionable feedback to boost resume quality, Assist candidates in acing top tech & business roles
//           </Typography>
//         </Box>
  
//         {/* Button */}
//         <Box className="btn" sx={{ display: "flex", justifyContent: "end" }}>
//           <Button
//             sx={{
//               fontSize: "16px",
//               fontStyle: "normal",
//               fontWeight: "600",
//               lineHeight: "normal",
//               color: "white",
//               fontFamily: "Outfit",
//               borderRadius: "8px",
//               background: "#7234F7",
//               padding: "10px 56px",
//               marginTop: "5px",
//               whiteSpace: "nowrap",
//               minWidth: "150px",
//               "&:hover": {
//                 background: "rgba(114, 52, 247)",
//               },
//               textTransform: "none",
//             }}
//             onClick={handleOpenMockInterview}
//           >
//             Register Now
//           </Button>
//         </Box>
//       </Box>
//     </Box>
  
//     {/* Dialog Component */}
//     <Dialog open={openMockInterview} onClose={handleCloseMockInterview}>
//       <MockInterview
//         courseContent={{ course: "Mock Interview" }}
//         handleCloseDialog={handleCloseMockInterview}
//       />
//     </Dialog>
//   </Box>
//   <Box
//     sx={{
//       backgroundColor: "#FFF",
//       margin: "0 20px",
//       position: "relative",
//       zIndex: 2,
//     }}
//   >
//     <Box
//       className="wrapper-1"
//       sx={{
//         alignItems: "center",
//         borderRadius: "20px",
//         border: "1px solid #EDEDED",
//         display: "flex",
//         padding: "24px",
//         gap: "24px",
//         justifyContent: "space-between",
//         margin: { md: "5px 50px", xs: "5px 20px" },
//       }}
//     >
//       {/* Image Box */}
//       <Box
//         className="img-join"
//         sx={{ width: "326px", height: "200px" }}
//         component="img"
//         src={Image1}
//       />
  
//       {/* Content Box */}
//       <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
//         <Box
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-between",
//             width: "100%",
//           }}
//         >
//           <Box
//             className="free"
//             sx={{
//               width: "117px",
//               padding: "5px 0",
//               fontFamily: "Outfit",
//               fontStyle: "normal",
//               fontWeight: "400",
//               lineHeight: "normal",
//               color: "black",
//               borderRadius: "20px",
//               border: "0.5px solid #000",
//               textAlign: "center",
//             }}
//           >
//             Free
//           </Box>
  
//           {/* Avatar Group */}
//           <AvatarGroup total={10} spacing="small" className="avg-G">
//             <Avatar alt="a1" src={a1} className="avg-i" />
//             <Avatar alt="a2" src={a2} className="avg-i" />
//             <Avatar alt="a3" src={a3} className="avg-i" />
//             <Avatar alt="a4" src={a4} className="avg-i" />
//           </AvatarGroup>
//         </Box>
  
//         {/* Text Content */}
//         <Box className="text-content">
//           <Typography
//             fontFamily="Outfit"
//             fontSize="24px"
//             fontWeight="500"
//             lineHeight="normal"
//             paddingBottom="10px"
//             sx={{
//               paddingTop: "24px",
//               fontSize: { xs: "18px !important" },
//             }}
//           >
//             Host Group Coaching & Webinars
//           </Typography>
//           <Typography
//             fontFamily="Poppins"
//             fontSize="14px"
//             fontStyle="normal"
//             lineHeight="normal"
//           >
//             Conduct live masterclasses & cohort-based training, Reach a wider audience and establish authority in your domain, Create a community of learners & professionals
//           </Typography>
//         </Box>
  
//         {/* Button */}
//         <Box className="btn" sx={{ display: "flex", justifyContent: "end" }}>
//           <Button
//             sx={{
//               fontSize: "16px",
//               fontStyle: "normal",
//               fontWeight: "600",
//               lineHeight: "normal",
//               color: "white",
//               fontFamily: "Outfit",
//               borderRadius: "8px",
//               background: "#7234F7",
//               padding: "10px 56px",
//               marginTop: "5px",
//               whiteSpace: "nowrap",
//               minWidth: "150px",
//               "&:hover": {
//                 background: "rgba(114, 52, 247)",
//               },
//               textTransform: "none",
//             }}
//             onClick={handleOpenMockInterview}
//           >
//             Register Now
//           </Button>
//         </Box>
//       </Box>
//     </Box>
  
//     {/* Dialog Component */}
//     <Dialog open={openMockInterview} onClose={handleCloseMockInterview}>
//       <MockInterview
//         courseContent={{ course: "Mock Interview" }}
//         handleCloseDialog={handleCloseMockInterview}
//       />
//     </Dialog>
//   </Box>
//   </>
//   );
// };

// export default Join;


// import React, { useState } from "react";
// import "./join.scss";
// import Image1 from "../../../assets/images/mockInterview/1st-join.png";
// import a1 from "../../../assets/images/mockInterview/a1.svg";
// import a2 from "../../../assets/images/mockInterview/a2.svg";
// import a3 from "../../../assets/images/mockInterview/a3.svg";
// import a4 from "../../../assets/images/mockInterview/a4.svg";
// import Image2 from "../../../assets/images/mockInterview/2nd-join.png";
// import { Box, Button, Typography, Dialog } from "@mui/material";
// import Avatar from "@mui/material/Avatar";
// import AvatarGroup from "@mui/material/AvatarGroup";

// import MockInterview from "./mockinterview/mockinterviewform";

// const Join = ({ targetJoinNow }) => {
//   // State to handle dialog visibility
//   const [openMockInterview, setOpenMockInterview] = useState(false);

//   // Function to handle opening the dialog
//   const handleOpenMockInterview = () => {
//     setOpenMockInterview(true);
//   };

//   // Function to handle closing the dialog
//   const handleCloseMockInterview = () => {
//     setOpenMockInterview(false);
//   };

//   return (
//     <Box
//       sx={{
//         backgroundColor: "#FFF",
//         margin: "0 20px",
//         position: "relative",
//         zIndex: 2,
//       }}
//     >
//       <Typography
//         ref={targetJoinNow}
//         style={{ scrollMarginTop: "60px" }}
//         className="header-join"
//       >
//         <span style={{ color: "#FE7465" }}>Why Join</span> Coachvita?
//       </Typography>

//       {/* 1:1 Career Mentorship Section */}
//       <Box
//         className="wrapper-1"
//         sx={{
//           alignItems: "center",
//           borderRadius: "20px",
//           border: "1px solid #EDEDED",
//           display: "flex",
//           padding: "24px",
//           gap: "24px",
//           justifyContent: "space-between",
//           margin: { md: "5px 50px", xs: "5px 20px" },
//         }}
//       >
//         <Box
//           className="img-join"
//           sx={{ width: "326px", height: "200px" }}
//           component="img"
//           src={Image1}
//         ></Box>
//         <Box sx={{ display: "flex", flexDirection: "column" }}>
//           <Box
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "space-between",
//               width: "100%",
//             }}
//           >
//             <Box
//               className="free"
//               sx={{
//                 width: "117px",
//                 padding: "5px 0",
//                 fontFamily: "Outfit",
//                 fontStyle: "normal",
//                 fontWeight: "400",
//                 lineHeight: "normal",
//                 color: "black",
//                 borderRadius: "20px",
//                 border: "0.5px solid #000",
//                 textAlign: "center",
//               }}
//             >
//               Free
//             </Box>
//             <AvatarGroup total={10} spacing="small" className="avg-G">
//               <Avatar alt="a1" src={a1} className="avg-i" />
//               <Avatar alt="a2" src={a2} className="avg-i" />
//               <Avatar alt="a3" src={a3} className="avg-i" />
//               <Avatar alt="a4" src={a4} className="avg-i" />
//             </AvatarGroup>
//           </Box>
//           <Box className="text-content">
//             <Typography
//               fontFamily="Outfit"
//               fontSize="24px "
//               fontWeight="500"
//               lineHeight="normal"
//               paddingBottom="10px"
//               sx={{
//                 paddingTop: "24px",
//                 fontSize: { xs: "18px !important" },
//               }}
//             >
//               1 : 1 Career Mentorship
//             </Typography>
//             <Typography
//               fontFamily="Poppins"
//               fontSize="14px"
//               fontStyle="normal"
//               lineHeight="normal"
//             >
//               Provide personalized coaching for learners,
//               Guide them on skills, career paths, and job readiness,
//               Enhance your leadership and mentoring skills
//             </Typography>
//           </Box>
//           <Box className="btn" sx={{ display: "flex", justifyContent: "end" }}>
//             <Button
//               sx={{
//                 fontSize: "16px",
//                 fontStyle: "normal",
//                 fontWeight: "600",
//                 lineHeight: "normal",
//                 color: "white",
//                 fontFamily: "Outfit",
//                 borderRadius: "8px",
//                 background: "#7234F7",
//                 padding: "10px 56px",
//                 marginTop: "5px",
//                 whiteSpace: "nowrap", // Prevent text wrapping
//                 minWidth: "150px", // Ensure the button has a minimum width to accommodate the text
//                 "&:hover": {
//                   background: "rgba(114, 52, 247)",
//                 },
//                 textTransform: "none",
//               }}
//               onClick={handleOpenMockInterview} // Open Mock Interview dialog
//             >
//               Register Now
//             </Button>
//           </Box>
//         </Box>
//       </Box>

//       {/* Mock Interview Dialog */}
//       <Dialog open={openMockInterview} onClose={handleCloseMockInterview}>
//         <MockInterview />
//       </Dialog>

//       {/* Repeat for other sections if necessary */}
//       {/* The sections for Mock Interview, Resume Reviews, etc., can have similar structures */}
//       {/* Mock Interview and Resume Reviews Section */}
//       <Box
//         className="wrapper-1"
//         sx={{
//           alignItems: "center",
//           borderRadius: "20px",
//           border: "1px solid #EDEDED",
//           display: "flex",
//           padding: "24px",
//           gap: "24px",
//           justifyContent: "space-between",
//           margin: { md: "5px 50px", xs: "5px 20px" },
//         }}
//       >
//         <Box
//           className="img-join"
//           sx={{ width: "326px", height: "200px" }}
//           component="img"
//           src={Image2}
//         ></Box>
//         <Box sx={{ display: "flex", flexDirection: "column" }}>
//           <Box
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "space-between",
//               width: "100%",
//             }}
//           >
//             <Box
//               className="free"
//               sx={{
//                 width: "117px",
//                 padding: "5px 0",
//                 fontFamily: "Outfit",
//                 fontStyle: "normal",
//                 fontWeight: "400",
//                 lineHeight: "normal",
//                 color: "black",
//                 borderRadius: "20px",
//                 border: "0.5px solid #000",
//                 textAlign: "center",
//               }}
//             >
//               Free
//             </Box>
//             <AvatarGroup total={10} spacing="small" className="avg-G">
//               <Avatar alt="a1" src={a1} className="avg-i" />
//               <Avatar alt="a2" src={a2} className="avg-i" />
//               <Avatar alt="a3" src={a3} className="avg-i" />
//               <Avatar alt="a4" src={a4} className="avg-i" />
//             </AvatarGroup>
//           </Box>
//           <Box className="text-content">
//             <Typography
//               fontFamily="Outfit"
//               fontSize="25px"
//               fontWeight="500"
//               lineHeight="normal"
//               paddingBottom="10px"
//               sx={{ paddingTop: "20px", fontSize: { xs: "18px !important" } }}
//             >
//               Mock Interviews and Resume Reviews
//             </Typography>
//             <Typography
//               fontFamily="Poppins"
//               fontSize="14px"
//               fontStyle="normal"
//               lineHeight="normal"
//             >
//               Help job seekers practice real-world interview scenarios,
//               Provide actionable feedback to boost resume quality,
//               Assist candidates in acing top tech & business roles
//             </Typography>
//           </Box>
//           <Box className="btn" sx={{ display: "flex", justifyContent: "end" }}>
//             <Button
//               sx={{
//                 fontSize: "16px",
//                 fontStyle: "normal",
//                 fontWeight: "600",
//                 lineHeight: "normal",
//                 color: "white",
//                 fontFamily: "Outfit",
//                 borderRadius: "8px",
//                 background: "#7234F7",
//                 padding: "10px 56px",
//                 marginTop: "5px",
//                 whiteSpace: "nowrap", // Prevent text wrapping
//                 minWidth: "150px", // Ensure the button has a minimum width to accommodate the text
//                 "&:hover": {
//                   background: "rgba(114, 52, 247)",
//                 },
//                 textTransform: "none",
//               }}
//             >
//               Register Now
//             </Button>
//           </Box>
//         </Box>
//       </Box>

//       {/* Group Coaching and Webinars Section */}
//       <Box
//         className="wrapper-1"
//         sx={{
//           alignItems: "center",
//           borderRadius: "20px",
//           border: "1px solid #EDEDED",
//           display: "flex",
//           padding: "24px",
//           gap: "24px",
//           justifyContent: "space-between",
//           margin: { md: "5px 50px", xs: "5px 20px" },
//         }}
//       >
//         <Box
//           className="img-join"
//           sx={{ width: "326px", height: "200px" }}
//           component="img"
//           src={Image1}
//         ></Box>
//         <Box sx={{ display: "flex", flexDirection: "column" }}>
//           <Box
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "space-between",
//               width: "100%",
//             }}
//           >
//             <Box
//               className="free"
//               sx={{
//                 width: "117px",
//                 padding: "5px 0",
//                 fontFamily: "Outfit",
//                 fontStyle: "normal",
//                 fontWeight: "400",
//                 lineHeight: "normal",
//                 color: "black",
//                 borderRadius: "20px",
//                 border: "0.5px solid #000",
//                 textAlign: "center",
//               }}
//             >
//               Free
//             </Box>
//             <AvatarGroup total={10} spacing="small" className="avg-G">
//               <Avatar alt="a1" src={a1} className="avg-i" />
//               <Avatar alt="a2" src={a2} className="avg-i" />
//               <Avatar alt="a3" src={a3} className="avg-i" />
//               <Avatar alt="a4" src={a4} className="avg-i" />
//             </AvatarGroup>
//           </Box>
//           <Box className="text-content">
//             <Typography
//               fontFamily="Outfit"
//               fontSize="24px "
//               fontWeight="500"
//               lineHeight="normal"
//               paddingBottom="10px"
//               sx={{
//                 paddingTop: "24px",
//                 fontSize: { xs: "18px !important" },
//               }}
//             >
//               Host Group Coaching & Webinars
//             </Typography>
//             <Typography
//               fontFamily="Poppins"
//               fontSize="14px"
//               fontStyle="normal"
//               lineHeight="normal"
//             >
//               Conduct live masterclasses & cohort-based training,
//               Reach a wider audience and establish authority in your domain,
//               Create a community of learners & professionals.
//             </Typography>
//           </Box>
//           <Box className="btn" sx={{ display: "flex", justifyContent: "end" }}>
//             <Button
//               sx={{
//                 fontSize: "16px",
//                 fontStyle: "normal",
//                 fontWeight: "600",
//                 lineHeight: "normal",
//                 color: "white",
//                 fontFamily: "Outfit",
//                 borderRadius: "8px",
//                 background: "#7234F7",
//                 padding: "10px 56px",
//                 marginTop: "5px",
//                 whiteSpace: "nowrap", // Prevent text wrapping
//                 minWidth: "150px", // Ensure the button has a minimum width to accommodate the text
//                 "&:hover": {
//                   background: "rgba(114, 52, 247)",
//                 },
//                 textTransform: "none",
//               }}
//             >
//               Register Now
//             </Button>
//           </Box>
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default Join;



// import "./join.scss";
// import Image1 from "../../../assets/images/mockInterview/1st-join.png";
// import a1 from "../../../assets/images/mockInterview/a1.svg";
// import a2 from "../../../assets/images/mockInterview/a2.svg";
// import a3 from "../../../assets/images/mockInterview/a3.svg";
// import a4 from "../../../assets/images/mockInterview/a4.svg";
// import Image2 from "../../../assets/images/mockInterview/2nd-join.png";
// import { Box, Button, Typography } from "@mui/material";
// import Avatar from "@mui/material/Avatar";
// import AvatarGroup from "@mui/material/AvatarGroup";


// import MockInterview from "./mockinterview/mockinterviewform";
// const Join = ({ targetJoinNow }) => {
//   return (
//     <Box
//       sx={{
//         backgroundColor: "#FFF",
//         margin: "0 20px",
//         position: "relative",
//         zIndex: 2,
//       }}
//     >
//       <Typography
//         ref={targetJoinNow}
//         style={{ scrollMarginTop: "60px" }}
//         className="header-join"
//       >
//         <span style={{ color: "#FE7465" }}>Why Join</span> Coachvita?
//       </Typography>

//       {/* 1:1 Career Mentorship Section */}
//       <Box
//         className="wrapper-1"
//         sx={{
//           alignItems: "center",
//           borderRadius: "20px",
//           border: "1px solid #EDEDED",
//           display: "flex",
//           padding: "24px",
//           gap: "24px",
//           justifyContent: "space-between",
//           margin: { md: "5px 50px", xs: "5px 20px" },
//         }}
//       >
//         <Box
//           className="img-join"
//           sx={{ width: "326px", height: "200px" }}
//           component="img"
//           src={Image1}
//         ></Box>
//         <Box sx={{ display: "flex", flexDirection: "column" }}>
//           <Box
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "space-between",
//               width: "100%",
//             }}
//           >
//             <Box
//               className="free"
//               sx={{
//                 width: "117px",
//                 padding: "5px 0",
//                 fontFamily: "Outfit",
//                 fontStyle: "normal",
//                 fontWeight: "400",
//                 lineHeight: "normal",
//                 color: "black",
//                 borderRadius: "20px",
//                 border: "0.5px solid #000",
//                 textAlign: "center",
//               }}
//             >
//               Free
//             </Box>
//             <AvatarGroup total={10} spacing="small" className="avg-G">
//               <Avatar alt="a1" src={a1} className="avg-i" />
//               <Avatar alt="a2" src={a2} className="avg-i" />
//               <Avatar alt="a3" src={a3} className="avg-i" />
//               <Avatar alt="a4" src={a4} className="avg-i" />
//             </AvatarGroup>
//           </Box>
//           <Box className="text-content">
//             <Typography
//               fontFamily="Outfit"
//               fontSize="24px "
//               fontWeight="500"
//               lineHeight="normal"
//               paddingBottom="10px"
//               sx={{
//                 paddingTop: "24px",
//                 fontSize: { xs: "18px !important" },
//               }}
//             >
//               1 : 1 Career Mentorship
//             </Typography>
//             <Typography
//               fontFamily="Poppins"
//               fontSize="14px"
//               fontStyle="normal"
//               lineHeight="normal"
//             >
//               Provide personalized coaching for learners,
//               Guide them on skills, career paths, and job readiness,
//               Enhance your leadership and mentoring skills
//             </Typography>
//           </Box>
//           <Box className="btn" sx={{ display: "flex", justifyContent: "end" }}>
//             <Button
//               sx={{
//                 fontSize: "16px",
//                 fontStyle: "normal",
//                 fontWeight: "600",
//                 lineHeight: "normal",
//                 color: "white",
//                 fontFamily: "Outfit",
//                 borderRadius: "8px",
//                 background: "#7234F7",
//                 padding: "10px 56px",
//                 marginTop: "5px",
//                 whiteSpace: "nowrap", // Prevent text wrapping
//                 minWidth: "150px", // Ensure the button has a minimum width to accommodate the text
//                 "&:hover": {
//                   background: "rgba(114, 52, 247)",
//                 },
//                 textTransform: "none",
//               }}
//             >
//               Register Now
//             </Button>
//           </Box>
//         </Box>
//       </Box>

//       {/* Mock Interview and Resume Reviews Section */}
//       <Box
//         className="wrapper-1"
//         sx={{
//           alignItems: "center",
//           borderRadius: "20px",
//           border: "1px solid #EDEDED",
//           display: "flex",
//           padding: "24px",
//           gap: "24px",
//           justifyContent: "space-between",
//           margin: { md: "5px 50px", xs: "5px 20px" },
//         }}
//       >
//         <Box
//           className="img-join"
//           sx={{ width: "326px", height: "200px" }}
//           component="img"
//           src={Image2}
//         ></Box>
//         <Box sx={{ display: "flex", flexDirection: "column" }}>
//           <Box
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "space-between",
//               width: "100%",
//             }}
//           >
//             <Box
//               className="free"
//               sx={{
//                 width: "117px",
//                 padding: "5px 0",
//                 fontFamily: "Outfit",
//                 fontStyle: "normal",
//                 fontWeight: "400",
//                 lineHeight: "normal",
//                 color: "black",
//                 borderRadius: "20px",
//                 border: "0.5px solid #000",
//                 textAlign: "center",
//               }}
//             >
//               Free
//             </Box>
//             <AvatarGroup total={10} spacing="small" className="avg-G">
//               <Avatar alt="a1" src={a1} className="avg-i" />
//               <Avatar alt="a2" src={a2} className="avg-i" />
//               <Avatar alt="a3" src={a3} className="avg-i" />
//               <Avatar alt="a4" src={a4} className="avg-i" />
//             </AvatarGroup>
//           </Box>
//           <Box className="text-content">
//             <Typography
//               fontFamily="Outfit"
//               fontSize="25px"
//               fontWeight="500"
//               lineHeight="normal"
//               paddingBottom="10px"
//               sx={{ paddingTop: "20px", fontSize: { xs: "18px !important" } }}
//             >
//               Mock Interviews and Resume Reviews
//             </Typography>
//             <Typography
//               fontFamily="Poppins"
//               fontSize="14px"
//               fontStyle="normal"
//               lineHeight="normal"
//             >
//               Help job seekers practice real-world interview scenarios,
//               Provide actionable feedback to boost resume quality,
//               Assist candidates in acing top tech & business roles
//             </Typography>
//           </Box>
//           <Box className="btn" sx={{ display: "flex", justifyContent: "end" }}>
//             <Button
//               sx={{
//                 fontSize: "16px",
//                 fontStyle: "normal",
//                 fontWeight: "600",
//                 lineHeight: "normal",
//                 color: "white",
//                 fontFamily: "Outfit",
//                 borderRadius: "8px",
//                 background: "#7234F7",
//                 padding: "10px 56px",
//                 marginTop: "5px",
//                 whiteSpace: "nowrap", // Prevent text wrapping
//                 minWidth: "150px", // Ensure the button has a minimum width to accommodate the text
//                 "&:hover": {
//                   background: "rgba(114, 52, 247)",
//                 },
//                 textTransform: "none",
//               }}
//             >
//               Register Now
//             </Button>
//           </Box>
//         </Box>
//       </Box>

//       {/* Group Coaching and Webinars Section */}
//       <Box
//         className="wrapper-1"
//         sx={{
//           alignItems: "center",
//           borderRadius: "20px",
//           border: "1px solid #EDEDED",
//           display: "flex",
//           padding: "24px",
//           gap: "24px",
//           justifyContent: "space-between",
//           margin: { md: "5px 50px", xs: "5px 20px" },
//         }}
//       >
//         <Box
//           className="img-join"
//           sx={{ width: "326px", height: "200px" }}
//           component="img"
//           src={Image1}
//         ></Box>
//         <Box sx={{ display: "flex", flexDirection: "column" }}>
//           <Box
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "space-between",
//               width: "100%",
//             }}
//           >
//             <Box
//               className="free"
//               sx={{
//                 width: "117px",
//                 padding: "5px 0",
//                 fontFamily: "Outfit",
//                 fontStyle: "normal",
//                 fontWeight: "400",
//                 lineHeight: "normal",
//                 color: "black",
//                 borderRadius: "20px",
//                 border: "0.5px solid #000",
//                 textAlign: "center",
//               }}
//             >
//               Free
//             </Box>
//             <AvatarGroup total={10} spacing="small" className="avg-G">
//               <Avatar alt="a1" src={a1} className="avg-i" />
//               <Avatar alt="a2" src={a2} className="avg-i" />
//               <Avatar alt="a3" src={a3} className="avg-i" />
//               <Avatar alt="a4" src={a4} className="avg-i" />
//             </AvatarGroup>
//           </Box>
//           <Box className="text-content">
//             <Typography
//               fontFamily="Outfit"
//               fontSize="24px "
//               fontWeight="500"
//               lineHeight="normal"
//               paddingBottom="10px"
//               sx={{
//                 paddingTop: "24px",
//                 fontSize: { xs: "18px !important" },
//               }}
//             >
//               Host Group Coaching & Webinars
//             </Typography>
//             <Typography
//               fontFamily="Poppins"
//               fontSize="14px"
//               fontStyle="normal"
//               lineHeight="normal"
//             >
//               Conduct live masterclasses & cohort-based training,
//               Reach a wider audience and establish authority in your domain,
//               Create a community of learners & professionals.
//             </Typography>
//           </Box>
//           <Box className="btn" sx={{ display: "flex", justifyContent: "end" }}>
//             <Button
//               sx={{
//                 fontSize: "16px",
//                 fontStyle: "normal",
//                 fontWeight: "600",
//                 lineHeight: "normal",
//                 color: "white",
//                 fontFamily: "Outfit",
//                 borderRadius: "8px",
//                 background: "#7234F7",
//                 padding: "10px 56px",
//                 marginTop: "5px",
//                 whiteSpace: "nowrap", // Prevent text wrapping
//                 minWidth: "150px", // Ensure the button has a minimum width to accommodate the text
//                 "&:hover": {
//                   background: "rgba(114, 52, 247)",
//                 },
//                 textTransform: "none",
//               }}
//             >
//               Register Now
//             </Button>
//           </Box>
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default Join;
