import axios from "axios";

const BASE_URL = process.env.REACT_APP_BACKEND_LINK;
console.log(BASE_URL);

export const getAllCareers = () => {
    const url = `${BASE_URL}/api/careers`;
    return axios.get(url);
};


export const getFilteredCareerResults = (payload) => {
    const url = `${BASE_URL}/api/careers/filter`;
    return axios.post(url, payload);
}

export const getSearchedCareerResults = (payload) => {
    const url = `${BASE_URL}/api/careers/search`;
    return axios.post(url, payload);
}

export const getCareerTags = (payload) => {
    const url = `${BASE_URL}/api/careers/tags`;
    return axios.post(url, payload);
}

export const getBlogsList = (payload) => {
    const url = `${BASE_URL}/api/blogs/search`;
    return axios.post(url, payload);
}

export const getTrendingBlogs = () => {
    const url = `${BASE_URL}/api/blogs/trending`;
    return axios.post(url, {});
}

export const getCourseList = (payload) => {
    const url = `${BASE_URL}/api/courses/search`;
    return axios.post(url, payload);
}