import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./events.scss";
import Footer from "../commonComponents/footer/Footer";
import Navbar from "../commonComponents/navbar/Navbar";
import EventsBentoGrid from "./eventsBentoGrid"; // Import the new component

const EventsPage = () => {
  const [events, setEvents] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    // In a real app, you would fetch from your API
    // For now, we'll import the JSON directly
    import("../../global/events.json")
      .then((data) => {
        setEvents(data.default);
      })
      .catch((error) => console.error("Error loading events:", error));
  }, []);

  const completedEvents = events.filter((event) => event.isCompleted);
  const upcomingEvents = events.filter((event) => !event.isCompleted);

  const filteredEvents = events.filter((event) =>
    event.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="events-main">
      <Navbar />
      <div className="events-page">
        <div className="hero-section2">
          <video autoPlay muted loop>
            <source
              src={require("../../assets/videos/eventsHero.mp4")}
              type="video/mp4"
            />
          </video>
          <div className="eventHero-content">
            <h1>Coursevita Events</h1>
            <h2>Learn, Network, Succeed.</h2>
            <p>
              Explore New Opportunities With Free Live Sessions Where You Can
              Develop Your Skills And Dive Into Your Passions. Learn Directly
              From Industry Experts With Years Of Experience.
            </p>
            <div className="search-bar">
              <input
                type="text"
                placeholder="Search Events..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />

              <button className="search-button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                >
                  <path
                    d="M15.1813 15.1813C14.4462 15.9164 13.5735 16.4995 12.613 16.8974C11.6525 17.2952 10.623 17.5 9.58342 17.5C7.48378 17.5 5.47015 16.6659 3.98549 15.1813C2.50082 13.6966 1.66675 11.683 1.66675 9.58332C1.66675 7.48369 2.50082 5.47006 3.98549 3.98539C5.47015 2.50073 7.48378 1.66666 9.58342 1.66666C10.623 1.66666 11.6525 1.87143 12.613 2.26928C13.5735 2.66713 14.4462 3.25026 15.1813 3.98539C15.9165 4.72053 16.4996 5.59325 16.8975 6.55375C17.2953 7.51424 17.5001 8.54369 17.5001 9.58332C17.5001 10.623 17.2953 11.6524 16.8975 12.6129C16.4996 13.5734 15.9165 14.4461 15.1813 15.1813ZM15.1813 15.1813L20.0001 20"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Search
              </button>
            </div>
          </div>
        </div>

        <div className="events-container">
          <div className="events-section">
            <div className="section-header">
              <h2>All Events</h2>
              <Link to="#" className="view-more">
                View more
              </Link>
            </div>
            <div className="events-grid">
              {completedEvents.map((event) => (
                <div key={event.id} className="event-card">
                  <div className="recording-badge">
                    <span className="icon">
                        <img
                            src={require("../../assets/images/events/mic.svg").default}
                            alt="mic"
                          />
                        </span>Recorded session</div>
                  <div className="event-image">
                    <img
                      src={event.image || "/api/placeholder/400/320"}
                      alt={event.title}
                    />
                  </div>
                  <div className="event-details">
                    <h3>{event.title}</h3>
                    <div className="event-meta">
                      <div className="meta-item">
                        <span className="icon">
                        <img
                            src={require("../../assets/images/events/calender.svg").default}
                            alt="Calendar"
                          />
                        </span>
                        <span>{event.date}</span>
                      </div>
                      <div className="meta-item">
                        <span className="icon">
                        <img
                            src={require("../../assets/images/events/location.svg").default}
                            alt="location"
                          />
                        </span>
                        <span>{event.locationName}</span>
                      </div>
                    </div>
                    <Link
                      to={`/events/${event.id}`}
                      className="action-button know-more"
                    >
                      Know more
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="events-section">
            <h2>Upcoming Events</h2>
            <div className="events-grid">
              {upcomingEvents.map((event) => (
                <div key={event.id} className="event-card">
                  <div className="recording-badge">
                  <span className="icon">
                        <img
                            src={require("../../assets/images/events/mic.svg").default}
                            alt="mic"
                          />
                        </span>
                        Recorded session</div>
                  <div className="event-image">
                    <img
                      src={event.image || "/api/placeholder/400/320"}
                      alt={event.title}
                    />
                  </div>
                  <div className="event-details">
                    <h3>{event.title}</h3>
                    <div className="event-meta">
                      <div className="meta-item">
                        <span className="icon">
                          <img
                            src={require("../../assets/images/events/calender.svg").default}
                            alt="Calendar"
                          />
                        </span>
                        <span>{event.date}</span>
                      </div>
                      <div className="meta-item">
                        <span className="icon">
                        <img
                            src={require("../../assets/images/events/location.svg").default}
                            alt="location"
                          />
                        </span>
                        <span>{event.locationName}</span>
                      </div>
                    </div>
                    <Link
                      to={`/events/${event.id}`}
                      className="action-button register-now"
                    >
                      Register Now
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Add the Bento Grid component here */}
        <EventsBentoGrid />
      </div>
      <Footer />
    </div>
  );
};

export default EventsPage;
