import { Box } from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import Finder from "./finder/Finder";
import Footer from "../commonComponents/footer/Footer";
import {
  getSearchedCareerResults,
  getCareerTags,
  getAllCareers,
} from "../../services/service-index";
import { HttpStatusCode } from "axios";
import Navbar from "../commonComponents/navbar/Navbar";
import { FILTER_FIELDS } from "../../constants/common-constants";
import Filters from "./filters/Filters";
import CoursesCatalog from "./coursesCatalog/CoursesCatalog";
import AllCourseResults from "./coursesCatalog/AllCourseResults";
import { sort } from "../../utils/utils";

import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

import { Helmet } from "react-helmet";

const FindYourCareerPage = () => {
  const [input, setInput] = useState("");
  const [prompts, setPrompts] = useState([]);
  const [isSticky, setIsSticky] = useState(false);
  const [careersList, setCareersList] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [filters, setFilters] = useState({});
  const [loader, setLoader] = useState(false);
  const [promptsLoader, setPromptsLoader] = useState(false);
  const [showPrompts, setShowPrompts] = useState(true);
  const [results, setResults] = useState([]);
  //
  const [noResultsFound, setNoResultsFound] = useState(false);
  //
  const isMobile = window.innerWidth < 900;

  const navigate = useNavigate();
  const { searchKey } = useParams();

  const resetPagination = () => {
    window.dispatchEvent(new Event("resetPagination"));
  };

  // Debounce function
  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  // Function to fetch search data
  const fetchSearchData = async (value) => {
    setPromptsLoader(true);
    getCareerTags({ searchKey: value })
      .then((response) => {
        if (response.status === HttpStatusCode.Ok) {
          setPrompts(response.data);
        }
      })
      .catch((err) => {
        console.log(err?.message);
      })
      .finally(() => {
        setPromptsLoader(false);
      });
  };

  const debouncedFetchSearchData = useCallback(
    debounce((value) => fetchSearchData(value), 300),
    []
  );

  const getCoursesBySearchKey = (input) => {
    setLoader(true);

    getSearchedCareerResults({ searchKey: input })
      .then((response) => {
        if (response.status === HttpStatusCode.Ok) {
          const careerResults = response.data?.results;
          setResults(careerResults);
          setCareersList(careerResults);
          setFilters(processResultFilters(careerResults || []));
        }
      })
      .catch((err) => console.log(err?.message))
      .finally(() => {
        setLoader(false);
      });
  };

  function handleSearchChange(value) {
    setInput(value);
    setShowPrompts(true);
    if (value) {
      debouncedFetchSearchData(value);
    } else {
      setPrompts([]);
    }
  }

  const handleKeyDown = (event) => {
    if (!!input && event.key === "Enter") {
      setShowPrompts(false);
      // if (prompts.length === 0) {
      //   return;
      // }
      getCoursesBySearchKey(input);
      window.scrollTo({
        top:
          document.getElementById("coursesCatalog")?.getBoundingClientRect()
            .top +
          window.scrollY -
          100,
        behavior: "smooth",
      });
    }
  };

  const handlePromptClick = (event) => {
    setInput(event);
    setPrompts([event]);
    setShowPrompts(false);
    getCoursesBySearchKey(event);
    window.scrollTo({
      top:
        document.getElementById("coursesCatalog")?.getBoundingClientRect().top +
        window.scrollY -
        100,
      behavior: "smooth",
    });
  };

  const handleSearch = () => {
    if (!!input) {
      setShowPrompts(false);
      getCoursesBySearchKey(input);
      // navigate(`/findyourcareer/search/${input}`);
      setPrompts([]);
      window.scrollTo({
        top:
          document.getElementById("coursesCatalog")?.getBoundingClientRect()
            .top +
          window.scrollY -
          100,
        behavior: "smooth",
      });
    }
  };

  const handleScroll = () => {
    const finderElement = document.getElementById("finderComponent");
    if (finderElement) {
      setIsSticky(
        window.scrollY >
        finderElement.offsetTop + finderElement.offsetHeight - 155
      );
    }
  };

  const processResultFilters = (list) => {
    const fields = {};
    list.forEach((doc) => {
      Object.keys(doc)
        .filter((key) => FILTER_FIELDS.includes(key))
        .forEach((key) => {
          if (!fields[key]) {
            fields[key] = new Set();
          }
          fields[key].add(doc[key]);
        });
    });
    for (const key in fields) {
      fields[key] = Array.from(fields[key]).sort();
    }
    return sort(fields);
  };

  // Add a state to track if filters have been applied
  const [filtersApplied, setFiltersApplied] = useState(false);



  useEffect(() => {
    setLoader(true);
  
    getAllCareers()
      .then((response) => {
        if (response.status === HttpStatusCode.Ok) {
          const careerResults = response.data?.results;
          setResults(careerResults);
          setCareersList(careerResults);
          
          // Set filters immediately from fetched data
          const processedFilters = processResultFilters(careerResults || []);
          setFilters(processedFilters);
        }
      })
      .catch((err) => console.error("Error fetching courses:", err))
      .finally(() => {
        setLoader(false);
      });
  }, []);
  
  // const processedFilters = useMemo(() => processResultFilters(results), [results]);


  const updateCareersList = () => {
    if (!results?.length) return;
  
    const filteredData = results.filter((career) => {
      return Object.keys(selectedFilters).every((key) => {
        if (!selectedFilters[key]?.options?.length) return true; // No filter applied
        return selectedFilters[key].options.includes(career[key]); // Match filter
      });
    });
    resetPagination();
    setCareersList(filteredData);
    setFiltersApplied(true);
  };
  

// Add a useEffect to fetch initial data when component mounts

useEffect(() => {
  // Fetch all courses initially to populate filters
  getSearchedCareerResults({ searchKey: "ALL" })
    .then((response) => {
      if (response.status === HttpStatusCode.Ok) {
        const careerResults = response.data?.results;
        setResults(careerResults);
        setCareersList(careerResults);
      }
    })
    .catch((err) => console.log(err?.message));

window.addEventListener("scroll", handleScroll);
if (searchKey) {
  fetchSearchData(searchKey);
}
return () => {
  window.removeEventListener("scroll", handleScroll);
};
}, []);


const skeletonArr = [1, 2, 3];

////
useEffect(() => {
  setNoResultsFound(!!input && !careersList.length);
}, [loader, careersList]);
////


return (
  <>
    <Helmet>
      <title>Find Your Career</title>
      <meta name="description" content="Find Your Career" />
      <meta name="keywords" content="find, career, guidance" />
    </Helmet>
    <Box sx={{ backgroundColor: "#F3F4FD" }}>
      <Navbar />
      <Box id="finderComponent">
        <Finder
          visibility={!(isSticky && isMobile)}
          handleSearchChange={handleSearchChange}
          input={input}
          isLoading={loader}
          prompts={prompts}
          isPromptsLoading={promptsLoader}
          showPrompts={showPrompts}
          setShowPrompts={setShowPrompts}
          handleKeyDown={handleKeyDown}
          onPromptClick={handlePromptClick}
          handleSearch={handleSearch}
        />
      </Box>

      {/* loader part  */}
      {loader ? (
        skeletonArr.map((item) => (
          <Stack
            key={item}
            direction="row"
            justifyContent="space-between"
            padding="32px"
            gap={"24px"}
          >
            <Box width="72%" sx={{ position: "relative", height: 200 }}>
              <Skeleton variant="rectangular" width="100%" height="100%" />
              <Skeleton
                variant="text"
                width="60%"
                height={30}
                sx={{
                  position: "absolute",
                  top: "20px",
                  left: "20px",
                  bgcolor: "rgba(255, 255, 255, 0.7)",
                }}
              />
              <Skeleton
                variant="text"
                width="80%"
                height={30}
                sx={{
                  position: "absolute",
                  top: "60px",
                  left: "20px",
                  bgcolor: "rgba(255, 255, 255, 0.7)",
                }}
              />
              <Skeleton
                variant="text"
                width="40%"
                height={30}
                sx={{
                  position: "absolute",
                  top: "100px",
                  left: "20px",
                  bgcolor: "rgba(255, 255, 255, 0.7)",
                }}
              />
            </Box>
            <Skeleton variant="rectangular" width={"28%"} height={200} />
          </Stack>
        ))
      ) : (
        <></>
      )}

      {/* filter part - with fixed position while scrolling */}
      <Box sx={{ position: "sticky", top: "80px", zIndex: 100 }}>
        <Filters
          // config={processedFilters}
          config = {filters}
          handleFilterChange={(filter) => {
            setSelectedFilters(filter); 
          }}
          handleApplyFilters={() => updateCareersList()}
          handleClearAllFilters={() => {
            resetPagination();
            setCareersList(results);
            setFiltersApplied(false); // Reset this when filters are cleared
          }}
        />
      </Box>

      {/* Show CoursesCatalog when filters are applied, otherwise show AllCourseResults */}

      {filtersApplied ? (
        <CoursesCatalog courses={careersList} />
      ) : (
        <AllCourseResults
          setLoader={setLoader}
          noResultsFound={!!input && !careersList.length}
        />
      )}

      <Footer />
    </Box>
  </>
);
};

export default FindYourCareerPage;
