import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import "./eventsPrev.scss";
import Footer from "../../commonComponents/footer/Footer";
import Navbar from "../../commonComponents/navbar/Navbar";

// Helper function to extract YouTube video ID from various YouTube URL formats
const getYoutubeVideoId = (url) => {
  if (!url) return null;

  // Handle different YouTube URL formats
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : null;
};

// Registration Form Popup Component
const RegistrationForm = ({ event, isWaitlist, onClose }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    eventTitle: event.title,
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // In the RegistrationForm component (EventDetailPage.js)
const handleSubmit = async (e) => {
  e.preventDefault();
  setLoading(true);
  setError("");

  try {
    const requestData = {
      eventTitle: formData.eventTitle,
      fullname: formData.fullName,
      email: formData.email,
      phone: formData.phone
    };

    // Different endpoints for registration and waitlist
    const endpoint = isWaitlist 
      ? `${process.env.REACT_APP_BACKEND_LINK}/api/joinevent` 
      : `${process.env.REACT_APP_BACKEND_LINK}/api/registerevent`;
    
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Something went wrong");
    }

    setSuccess(true);
    setFormData({
      ...formData,
      fullName: "",
      email: "",
      phone: "",
    });
  } catch (err) {
    setError(err.message);
  } finally {
    setLoading(false);
  }
};

  return (
    <div className="registration-popup-overlay">
      <div className="registration-popup">
        <div className="popup-header">
          <h3>{isWaitlist ? "Join Waitlist" : "Register for Event"}</h3>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>

        {success ? (
          <div className="success-message">
            <h4>Thank you for your {isWaitlist ? "interest" : "registration"}!</h4>
            <p>
              {isWaitlist
                ? "We've added you to our waitlist and will notify you when spots become available."
                : "Your registration was successful."}
            </p>
            <button className="primary-button" onClick={onClose}>
              Close
            </button>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Event</label>
              <input
                type="text"
                value={formData.eventTitle}
                disabled
                className="disabled-input"
              />
            </div>

            <div className="form-group">
              <label htmlFor="fullName">Full Name*</label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                required
                placeholder="Enter your full name"
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email*</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="Enter your email address"
              />
            </div>

            <div className="form-group">
              <label htmlFor="phone">Phone Number*</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
                placeholder="Enter your phone number"
              />
            </div>

            {error && <div className="error-message">{error}</div>}

            <div className="form-actions">
              <button type="button" className="secondary-button" onClick={onClose}>
                Cancel
              </button>
              <button
                type="submit"
                className="primary-button"
                disabled={loading}
              >
                {loading
                  ? "Processing..."
                  : isWaitlist
                  ? "Join Waitlist"
                  : "Register Now"}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

const EventDetailPage = () => {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showRegistrationForm, setShowRegistrationForm] = useState(false);

  useEffect(() => {
    // In a real app, you would fetch from your API
    // For now, we'll import the JSON directly
    import("../../../global/events.json")
      .then((data) => {
        const foundEvent = data.default.find((e) => e.id === parseInt(id));
        setEvent(foundEvent);

        // Get two random events where isCompleted is false
        const notCompletedEvents = data.default.filter(
          (e) => e.isCompleted === false && e.id !== parseInt(id)
        );

        // Pick random events if there are more than 2 upcoming events
        let selectedEvents = [];
        if (notCompletedEvents.length <= 2) {
          selectedEvents = notCompletedEvents;
        } else {
          // Get 2 random events from not completed events
          const shuffled = [...notCompletedEvents].sort(
            () => 0.5 - Math.random()
          );
          selectedEvents = shuffled.slice(0, 2);
        }

        setUpcomingEvents(selectedEvents);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error loading event:", error);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (!event) {
    return <div className="not-found">Event not found</div>;
  }

  // Extract YouTube video ID if it exists
  const youtubeVideoId = event.youtubeLink
    ? getYoutubeVideoId(event.youtubeLink)
    : null;

  return (
    <div className="prev-main">
      <Navbar />
      <div className="event-detail-page">
        <div className="breadcrumb">
          <Link to="/">Home</Link> &gt; <Link to="/events">Events</Link> &gt;{" "}
          <span>{event.title}</span>
        </div>

        <div className="event-container">
          <div className="event-sidebar">
            <div className="sidebar-header">
              <div className="hosted-by">
                <span>Hosted By</span>
                <div className="coursevita-logo">
                  <img
                    src={require("../../../assets/images/events/cv.png")}
                    alt="Coursevita"
                  />
                </div>
              </div>

              <div className="event-title-card">
                <h2>{event.title}</h2>
                <div className="event-date-time">
                  <span>{event.date}</span>
                  {event.time?.from && event.time?.upto && (
                    <span>
                      {event.time.from} - {event.time.upto}
                    </span>
                  )}
                </div>

                {event.locationName && (
                  <div className="event-location">
                    {event.address ? (
                      <>
                        <p>{event.locationName}</p>
                        <p className="location-address">{event.address}</p>
                      </>
                    ) : (
                      <p>{event.locationName}</p>
                    )}
                  </div>
                )}
              </div>

              {event.mentors && event.mentors.length > 0 && (
                <div className="mentors-section">
                  <h3>Mentors</h3>
                  <div className="mentors-avatars">
                    {event.mentors.map((mentor, index) => (
                      <div key={index} className="mentor-avatar">
                        <img
                          src={mentor.photo || "/api/placeholder/50/50"}
                          alt={mentor.name}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="hosted-by-people">
              <h3>Hosted By</h3>
              {event.hostedBy &&
                event.hostedBy.map((host, index) => (
                  <div key={index} className="host-info">
                    <div className="host-avatar">
                      <img
                        src={host.photo || "/api/placeholder/50/50"}
                        alt={host.name}
                      />
                    </div>
                    <div className="host-name">{host.name}</div>
                    <div className="host-social">
                      {host.instagramLink && (
                        <a
                          href={host.instagramLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="social-icon instagram"
                        >
                          <img
                            src={require("../../../assets/images/events/ig.png")}
                            alt="Instagram"
                            style={{ width: "20px", height: "20px" }}
                          />
                        </a>
                      )}
                      {host.linkedinLink && (
                        <a
                          href={host.linkedinLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="social-icon linkedin"
                        >
                          <img
                            src={require("../../../assets/images/events/x.png")}
                            alt="Instagram"
                            style={{ width: "20px", height: "20px" }}
                          />
                        </a>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="event-main-content">
            <div className="event-header">
              <h1>{event.title}</h1>
              <div className="event-meta">
                <div className="date-time">
                  <div className="icon">
                    <img
                      src={require("../../../assets/images/events/cal.png")}
                      alt="Calendar"
                      style={{ width: "35px", height: "35px" }}
                    />
                  </div>
                  <div className="info">
                    <div>{event.date}</div>
                    {event.time && (
                      <span style={{ color: "#8940FF" }}>
                        {event.time.from} - {event.time.upto}
                      </span>
                    )}
                  </div>
                </div>

                {event.locationName && (
                  <div className="location">
                    <div className="icon">
                      <img
                        src={require("../../../assets/images/events/loc.png")}
                        alt="Location"
                        style={{ width: "30px", height: "30px" }}
                      />
                    </div>
                    <div className="info">
                      {event.locationName}
                      {event.address && (
                        <span style={{ color: "#8940FF" }}>
                          {event.address}
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="registration-section">
              {event.isCompleted === true && (
                <div>
                  <h3>Event Completed</h3>
                  <p>
                    To join the upcoming event, please join the waitlist below.
                  </p>
                </div>
              )}
              {event.isCompleted === false && (
                <div>
                  <h3>Registration</h3>
                  <p>Welcome! To join the event, please register below.</p>
                </div>
              )}
              <button
                className={`register-button ${
                  event.isCompleted ? "join-waitlist" : "register-now"
                }`}
                onClick={() => setShowRegistrationForm(true)}
              >
                {event.isCompleted ? "Join Waitlist" : "Register Now"}
              </button>
            </div>

            <div className="about-event">
              <h3>About Event</h3>
              {event.isCompleted && event.youtubeLink && youtubeVideoId && (
                <div className="youtube-video">
                  <div className="video-container">
                    <iframe
                      width="100%"
                      height="348"
                      src={`https://www.youtube.com/embed/${youtubeVideoId}`}
                      title="Event Recording"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              )}
              <p>{event.about}</p>
            </div>

            {event.locationName && (
              <div id="location-section" className="location-section">
                <h3>Location</h3>
                <div className="location-name">{event.locationName}</div>
                {event.locationlink && (
                  <div className="map-container">
                    <div
                      className="map-responsive"
                      dangerouslySetInnerHTML={{ __html: event.locationlink }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="upcoming-events-sidebar">
            <h3>Upcoming Events</h3>

            {upcomingEvents.length > 0 ? (
              upcomingEvents.map((upcomingEvent) => (
                <Link
                  to={`/events/${upcomingEvent.id}`}
                  key={upcomingEvent.id}
                  className="upcoming-event-card"
                >
                  <div className="event-card">
                    <div className="event-image-container">
                      <img
                        src={upcomingEvent.image || "/api/placeholder/400/200"}
                        alt={upcomingEvent.title}
                        className="event-image"
                      />
                      <div className="recorded-badge">
                        <div className="record-icon">
                          <img
                            src={require("../../../assets/images/events/Mic.png")}
                            alt="Record"
                            style={{ width: "20px", height: "20px" }}
                          />
                        </div>
                        <span>Recorded session</span>
                      </div>
                    </div>

                    <div className="event-info">
                      <h4 className="event-title">{upcomingEvent.title}</h4>

                      <div className="event-details">
                        <div className="event-date">
                          <div className="icon">
                            <img
                              src={require("../../../assets/images/events/cal2.png")}
                              alt="Calendar"
                              style={{ width: "24px", height: "24px" }}
                            />
                          </div>
                          <span>
                            {upcomingEvent.date} |{" "}
                            {upcomingEvent.time?.from || "9AM"} onwards
                          </span>
                        </div>

                        <div className="event-location">
                          <div className="icon">
                            <img
                              src={require("../../../assets/images/events/loc2.png")}
                              alt="Location"
                              style={{ width: "24px", height: "24px" }}
                            />
                          </div>
                          <span>Coursevita, Hyderabad</span>
                        </div>
                      </div>

                      <button className="register-button">Register Now</button>
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <div className="no-upcoming-events">
                No upcoming events available at the moment.
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Registration/Waitlist Popup Form */}
      {showRegistrationForm && (
        <RegistrationForm
          event={event}
          isWaitlist={event.isCompleted}
          onClose={() => setShowRegistrationForm(false)}
        />
      )}

      <Footer />
    </div>
  );
};

export default EventDetailPage;