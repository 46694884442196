import "./topLayer.scss";
import React from "react";
import { Box, Button, Typography } from "@mui/material";
import Image1 from "../../../assets/images/mockInterview/1st.png";
import Image2 from "../../../assets/images/mockInterview/job.svg";
import Image3 from "../../../assets/images/mockInterview/arrow_.svg";
import Image4 from "../../../assets/images/mockInterview/bag.svg";
import Image5 from "../../../assets/images/mockInterview/5th.png";
import Rocket from "../../../assets/images/mockInterview/rocket.svg";
import Bulb from "../../../assets/images/mockInterview/lightbulb_final.svg";
import Correct from "../../../assets/images/mockInterview/correct.svg";
import WaterMark from "../../../assets/images/mockInterview/watermark.svg";
import c1 from "../../../assets/images/mockInterview/1.svg";
import c2 from "../../../assets/images/mockInterview/2.svg";
import c3 from "../../../assets/images/mockInterview/3.svg";
import c4 from "../../../assets/images/mockInterview/4.svg";
import AnimatedTypography from "../subComponents/AnimatedTypography";
import AnimatedCurves from "./AnimatedCurves";
const TopLayer = ({ targetJoinNow }) => {
  return (
    <Box className="gradient-background">
      <Box sx={{ margin: "0 20px" }}>
        <Box
          className="wrapper-box-1"
          sx={{ display: "flex", gap: "40px", alignItems: "center" }}
        >
          <Box
            className="wrapper-box-2"
            sx={{ display: "flex", flexDirection: "column", gap: {md:"25px", xs:"20px"}, paddingLeft:{md:"20px"},textAlign:{ xs:"center", md:"left"} }}
          >
            <span className="header-text-t" >Coursevita</span>
            <Typography className="header-text-t">
            Flexible Mentorship,Big Earnings! 
            </Typography>

            
            <Box
  sx={{
    display: "flex",
    flexDirection: "column", // Stacks elements in a column
    gap: "12px", // Space between items
    alignItems: { xs: "center", md: "flex-start" }, // Center on mobile, left-align on desktop
    textAlign: { xs: "center", md: "left" }, // Adjust text alignment accordingly
  }}
>
  {/* First Item */}
  <Box
    className="adv-b"
    sx={{
      display: "flex",
      alignItems: "left",
      flexDirection: "row",
      width: "100%",
    }}
  >
    <Box
      component="img"
      src={c2}
      sx={{ width: "22px", height: "22px" }}
    />
    <Typography sx={{ fontSize: "14px", color: "#000", lineHeight: "1.2" }}>
    Earn up to ₹<strong>5000</strong> / week on average with just <strong>4 hours</strong> of mentoring per week
    </Typography>
  </Box>

  {/* Second Item */}
  <Box
    className="adv-b"
    sx={{
      display: "flex",
      alignItems: "center",
      gap: "8px",
      flexDirection: "row",
      width: "100%",
    }}
  >
    <Box
      component="img"
      src={c3}
      sx={{ width: "19px", height: "19px" }}
    />
    <Typography sx={{ fontSize: "14px", color: "#000", lineHeight: "1.2" }}>
    Join an exclusive community of mentors guiding learners in <strong>projects, Simulation </strong> and <strong>more</strong>
    </Typography>
  </Box>

  {/* Third Item */}
  <Box
    className="adv-b"
    sx={{
      display: "flex",
      alignItems: "center",
      gap: "8px",
      flexDirection: "row",
      width: "100%",
    }}
  >
    <Box
      component="img"
      src={c1}
      sx={{ width: "18px", height: "18px" }}
    />
    <Typography sx={{ fontSize: "14px", color: "#000", lineHeight: "1.2",width:"100%" }}>
    Act as a <strong>Project manager, Scrum master </strong>and <strong>project mentor</strong>
    </Typography>
  </Box>
  <Box
    className="adv-b"
    sx={{
      display: "flex",
      alignItems: "center",
      gap: "8px",
      flexDirection: "row",
      width: "100%",
    }}
  >
    <Box
      component="img"
      src={Rocket}
      sx={{ width: "19px", height: "19px" }}
    />
    <Typography sx={{ fontSize: "14px", color: "#000", lineHeight: "1.2" }}>
    Get the chance to become a <strong>coursevita</strong> live 6-Months course mentor and grow in the <strong>Edtech industry</strong>
    </Typography>
  </Box>

  <Box
    className="adv-b"
    sx={{
      display: "flex",
      alignItems: "center",
      gap: "8px",
      flexDirection: "row",
      width: "100%",
    }}
  >
    <Box
      component="img"
      src={c4}
      sx={{ width: "19px", height: "19px" }}
    />
    <Typography sx={{ fontSize: "14px", color: "#000", lineHeight: "1.2" }}>
    Flexible work <strong>hours</strong> work from <strong>anywhere</strong>, on your own <strong>schedule!</strong>
    </Typography>
  </Box>
  
</Box>

            


















            {/* <Box className="adv-b">
                <Box
                  sx={{ width: "23px", height: "23px" }}
                  component="img"
                  src={Correct}
                />
                <Typography className="adv-text">
                  Achieve Your Career Goals
                </Typography>
              </Box> */}
            {/* <Box
                className="adv-b"
                sx={{
                  display: "flex",
                  alignItems: "left", // Aligns items vertically
                  justifyContent: "flex-start", // Ensures contents start from the left
                  gap: "8px", // Space between the icon and text
                  padding: "4px 0", // Adjust padding as needed
                  flexDirection: "row", // Makes the text stack below the icon
                  textAlign: "left", // Ensures text aligns to the left
                  "@media (min-width: 768px)": {
                    flexDirection: "column",
                    justifyContent: "flex-start"// For larger screens, show icon and text in a row
                  },
                }}
              >
                <Box
                  component="img"
                  src={Correct}
                  sx={{
                    width: "23px", // Adjust icon size as needed
                    height: "23px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "14px", // Adjust font size
                    color: "#000", // Set text color
                    textAlign: "left", // Ensures text alignment is left
                    lineHeight: "1.2", // Adjust line height if needed
                  }}
                >
                  Achieve Your Career Goals
                </Typography>
              </Box> */}
              <br></br>


            <Button
              sx={{
                justifyContent: "center", // Corrected property
                alignItems: "center", // Corrected property
              }}
              onClick={() => {
                if (targetJoinNow.current) {
                  targetJoinNow.current.scrollIntoView({ behavior: "smooth" });
                }
              }}
              className="gradient-but"
            >
              Become a Mentor Now
            </Button>

          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "25px",
              paddingLeft: { xs: "0", md: "150px" },
            }}
          >
            <Box component="img" src={Image1} className="img" />
            <Box
              className="img"
              sx={{
                position: "relative",
                borderRadius: "14px",
                border: "1px solid #E6E6E6",
              }}
            >
              <Box
                className="job_wrapper"
                sx={{
                  position: "absolute",
                  top: "16px",
                  left: "16px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "30px",
                  background:
                    "linear-gradient(179deg, rgba(247, 240, 255, 0.09)-175.98%, rgba(114, 52, 247, 0.20)218.99%)",
                }}
              >
                <Box className="job_" component="img" src={Image2}></Box>
              </Box>
              {/* <Box position="relative">
                <Box
                  className="curve-1"
                  top="71px"
                  left="30px"
                  position="absolute"
                  component="img"
                  src={curve1}
                ></Box>
                <Box
                  className="curve-2"
                  top=" 82px "
                  left="135px"
                  position="absolute"
                  component="img"
                  src={curve2}
                ></Box>
                <Typography
                  top="109px"
                  left="72px"
                  className="no_"
                  position="absolute"
                >
                  <AnimatedTypography>75</AnimatedTypography>%
                </Typography>
              </Box> */}
              <AnimatedCurves/>
              <Typography className="text_">
              Trusted by 10,000+ mentors & learners
              </Typography>
            </Box>
            <Box
              className="img"
              sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <Box className="wrapper-small-1">
                <Box display="flex" justifyContent="start" gap="14px">
                  <Box
                    alignSelf="start"
                    className="arrow"
                    component="img"
                    src={Image3}
                  />
                  <Box>
                    <Typography className="secondOutsideText">
                      <AnimatedTypography>100</AnimatedTypography>+
                    </Typography>
                    <Typography className="secondInsideText">
                    successful job placements every month
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="wrapper-small-2">
                <Box display="flex" justifyContent="start" gap="14px">
                  <Box
                    alignSelf="start"
                    className="bag"
                    component="img"
                    src={Image4}
                  />
                  <Box>
                    <Typography className="thirdOutsideText">
                      <AnimatedTypography>85</AnimatedTypography>%
                    </Typography>
                    <Typography className="thirdInsideText">
                    4.9/5 mentor ratings from learners
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box component="img" src={Image5} className="img" />
          </Box>
        </Box>
      </Box>
      <Box
        component="img"
        className="watermark-top"
        src={WaterMark}
        sx={{
          position: "absolute",
          bottom: "-20px",
          width: "750px",
          left: "80px",
          filter: "drop-shadow(0 0 0 #7233F7) drop-shadow(0 0 0 #FF7262)",
        }}
      ></Box>
    </Box>
  );
};

export default TopLayer;
