import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Snackbar, Link } from '@mui/material';
import { hasTrackingConsent, setTrackingConsent } from '../../../utils/cookieUtils';
import './CookieConsent.scss';

const CookieConsent = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // Check if user has already given consent
    const hasConsent = hasTrackingConsent();
    if (!hasConsent) {
      // Show the banner if no consent is found
      setOpen(true);
    }
  }, []);

  const handleAccept = () => {
    setTrackingConsent(true);
    setOpen(false);
  };

  const handleDecline = () => {
    setTrackingConsent(false);
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      className="cookie-consent-container"
    >
      <Box className="cookie-consent">
        <Typography variant="body1" className="cookie-text">
          We use cookies to enhance your experience and analyze our website traffic. 
          This information may be shared with advertising and analytics partners. 
          By clicking "Accept", you consent to our use of cookies. 
          <Link href="/privacy" className="privacy-link">
            Learn more in our Privacy Policy
          </Link>
        </Typography>
        <Box className="cookie-buttons">
          <Button 
            variant="outlined" 
            onClick={handleDecline}
            className="decline-button"
          >
            Decline
          </Button>
          <Button 
            variant="contained" 
            onClick={handleAccept}
            className="accept-button"
          >
            Accept
          </Button>
        </Box>
      </Box>
    </Snackbar>
  );
};

export default CookieConsent;