import React from "react";
import "./eventsGrid.scss";

const EventsBentoGrid = () => {
  return (
    <div className="bento-grid-container">
      <div className="bento-grid">
        <div className="bento-item large">
          <img src={require("../../assets/images/events/pairHack1.png")} alt="Pair Programming Hackathon" />
          <div className="bento-overlay">
            <h3>Pair Programming Hackathon</h3>
          </div>
        </div>
        
        <div className="bento-item wide">
          <img src={require("../../assets/images/events/snist.JPG")} alt="SNIST Hackathon" />
          <div className="bento-overlay">
            <h3>SNIST Hackathon</h3>
          </div>
        </div>
        
       <div 
          className="bento-item purple"
          style={{
            backgroundImage: `url(${require("../../assets/images/events/circle.png")})`,
            backgroundPosition: 'right bottom',
            backgroundSize: '55%',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <div className="bento-content">
            <h2>20+</h2>
            <p>Meetups<br />in different cities</p>
          </div>
        </div>

        <div className="bento-item student">
          <img src={require("../../assets/images/events/klu.JPG")} alt="KLU Hackathon" />
          <div className="bento-overlay">
            <h3>KLU Hackathon</h3>
          </div>
        </div>
        
        <div className="bento-item klu">
          <img src={require("../../assets/images/events/student.JPG")} alt="Student Interactions" />
          <div className="bento-overlay">
            <h3>Student Interactions</h3>
          </div>
        </div>
      </div>

      {/* Mobile version of bento grid */}
      <div className="bento-grid-mobile">
        <div className="bento-item">
          <img src={require("../../assets/images/events/pairHackathon.JPG")} alt="Pair Programming Hackathon" />
          <div className="bento-overlay">
            <h3>Pair Programming Hackathon</h3>
          </div>
        </div>

        <div 
          className="bento-item purple"
          style={{
            backgroundImage: `url(${require("../../assets/images/events/circle.png")})`,
            backgroundPosition: 'right bottom',
            backgroundSize: '55%',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <div className="bento-content">
            <h2>20+</h2>
            <p>Meetups<br />in different cities</p>
          </div>
        </div>

        <div className="bento-item">
         <img src={require("../../assets/images/events/snist.JPG")} alt="SNIST Hackathon" />
          <div className="bento-overlay">
            <h3>SNIST Hackathon</h3>
          </div>
        </div>

        <div className="bento-item">
          <img src={require("../../assets/images/events/klu.JPG")} alt="KLU Hackathon" />
          <div className="bento-overlay">
            <h3>KLU Hackathon</h3>
          </div>
        </div>
        
        <div className="bento-item">
          <img src={require("../../assets/images/events/student.JPG")} alt="Student Interactions" />
          <div className="bento-overlay">
            <h3>Student Interactions</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsBentoGrid;