import { Box, Typography } from "@mui/material";
import bgImg from "../../../assets/images/findYourCareer/bgImg.jfif";
import SearchBar from "../../commonComponents/searchBar/SearchBar";
import React, {  useEffect, useRef } from "react";
import "./Finder.scss";
import CVLoader from "../../commonComponents/loader/loader";

const Finder = (props) => {
  const searchWrapperRef = useRef(null);
  const handleClickOutside = (event) => {
    if (searchWrapperRef.current && !searchWrapperRef.current.contains(event.target)) {
      props.setShowPrompts(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFocus = () => {
    props.setShowPrompts(true);
  };

  return (
    <Box sx={{ p: { xs: "24px 16px 16px 16px", md: "20px 32px 0px 32px" } }}>
      <Box
        className="finderWrapper"
        sx={{
          backgroundImage: `url(${bgImg})`,
          maxHeight: "500px",
          height: {
            lg: "75vh",
            md: "90vh",
            sm: "45vh",
          },
          width: "100%",
          borderRadius: { xs: "12px", md: "14px" },
        }}
      >
        <Box
          className="overlayWrapper"
          sx={{
            borderRadius: { xs: "8px", md: "16px" },
            p: { xs: "40px 20px 48px 20px", md: "30px 110px" },
            width: { xs: "100%", md: "80%" },
            m: "38px 16px",
          }}
        >
          <Typography className="text1" sx={{ fontSize: { xs: "16px", md: "24px" } }}>
          Explore upskilling opportunities
          </Typography>
          <Typography className="text2" sx={{ fontSize: { xs: "14px", md: "20px" }, pb: { xs: "20px", md: "12px" } }}>
          Find certifications and diploma courses from top institutions
          </Typography>
          <Box className={!props.visibility ? "stickyContainer" : ""}>
            <Box className="searchWrapper" ref={searchWrapperRef}>
              <SearchBar
                placeholder="Search colleges, courses"
                handleChange={props.handleSearchChange}
                input={props.input}
                handleKeyDown={props.handleKeyDown}  
                onFocus={handleFocus}
                handleSearch={props.handleSearch}
              />
              {props.showPrompts && <Box className="promptList">
                {props.input ? <>
                  {props.isPromptsLoading ? <Box className="searchResult"><CVLoader justifyContent="flex-start"/></Box>
                    : <>{props?.prompts?.length > 0 ? props?.prompts?.map((prompt, index) => (
                      <Box key={index} className="searchResult" onClick={() => props.onPromptClick(prompt)}>
                        <Typography className="promptText">
                          {prompt}
                        </Typography>
                      </Box>
                    )) : <><Box key={'no-results'} className="searchResult"><Typography className="promptText">{props.input}....</Typography></Box></>}</>}
                </> : <></>}
              </Box>}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Finder;
