/**
 * Cookie utility functions for tracking user interactions
 * Used for remarketing purposes across platforms like Google Ads and Instagram
 */

// Set a cookie with a specified name, value and expiration days
export const setCookie = (name, value, days = 30) => {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/;SameSite=Lax`;
};

// Get a cookie value by name
export const getCookie = (name) => {
  const cookieName = `${name}=`;
  const cookies = document.cookie.split(';');
  
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i].trim();
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return "";
};

// Track a user interaction (like viewing a course)
export const trackUserInteraction = (interactionType, itemId, itemName) => {
  // Get existing interactions or initialize empty array
  let interactions = getCookie('userInteractions');
  let interactionsArray = [];
  
  if (interactions) {
    try {
      interactionsArray = JSON.parse(interactions);
    } catch (e) {
      console.error('Error parsing interactions cookie', e);
    }
  }
  
  // Add new interaction with timestamp
  interactionsArray.push({
    type: interactionType,
    id: itemId,
    name: itemName,
    timestamp: new Date().toISOString()
  });
  
  // Keep only the last 20 interactions to avoid large cookies
  if (interactionsArray.length > 20) {
    interactionsArray = interactionsArray.slice(-20);
  }
  
  // Save updated interactions
  setCookie('userInteractions', JSON.stringify(interactionsArray));
  
  // Return the updated array
  return interactionsArray;
};

// Get user's interaction history
export const getUserInteractions = () => {
  const interactions = getCookie('userInteractions');
  if (!interactions) return [];
  
  try {
    return JSON.parse(interactions);
  } catch (e) {
    console.error('Error parsing interactions cookie', e);
    return [];
  }
};

// Track specific course interactions
export const trackCourseInteraction = (courseId, courseName) => {
  return trackUserInteraction('course_view', courseId, courseName);
};

// Check if user has consent for tracking
export const hasTrackingConsent = () => {
  return getCookie('trackingConsent') === 'true';
};

// Set user consent for tracking
export const setTrackingConsent = (hasConsent) => {
  setCookie('trackingConsent', hasConsent ? 'true' : 'false', 365); // Store for a year
};