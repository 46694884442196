import { Box, Typography, Button, Pagination, Paper, TextField, InputAdornment } from "@mui/material";
import leftIcon from "../../../assets/images/blogPosts/leftIcon.svg";
import blogArrow from "../../../assets/images/blogPosts/blogArrow.svg";
import arrow from "../../../assets/images/testimonials/arrow.svg";
import { useEffect, useState } from "react";
import "../blogPosts/BlogPosts.scss";
// import { isEmpty } from "lodash";
import CourseCard from "../../landingPage/coursesSection/courseCard/CourseCard";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PreLoader from "../../../components/pre-loader/PreLoader";
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';

const BlogPosts = (props) => {
  const { coursesContent } = props;
  const navigate = useNavigate();

  const [blogs, setBlogs] = useState([]);
  const [pages, setPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  
  useEffect(() => {
    async function getBlogs() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_LINK}/api/blogs/getallblogs`);
        if (response.status === 200) {
          const { data, length } = response.data;

          // Reverse the blog data to show the latest first
          const reversedBlogs = data.reverse();

          setBlogs(reversedBlogs);
          setPages(length);
          setLoading(false);
        }
      } catch (error) {
        console.error(error.message);
        setLoading(false);
      }
    }

    getBlogs();
  }, []);


  const trendingData = blogs?.filter((blog) => blog.trending === true);
  const getUniqueCategories = (blogs) => {
    const categories = blogs.map((blog) => blog.blogCategory);
    const uniqueCategories = [...new Set(categories)];
    return ["All", ...uniqueCategories];
  };
  const blogCategories = getUniqueCategories(blogs);

  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = trendingData?.length;
  const [activeCategory, setActiveCategory] = useState("All");

  const [filteredData, setFilteredData] = useState(blogs);
  const [currentPage, setCurrentPage] = useState(1); // Start pagination from page 1
  const [filteredCourses, setFilteredCourses] = useState([]);


  var totalPages = 0;
  const cardsPerPage = 3;
  
  useEffect(() => {
    let filtered = blogs;
    
    // First filter by category
    if (activeCategory !== "All") {
      filtered = filtered.filter((blog) => blog.blogCategory === activeCategory);
    }
    
    // Then filter by search term if it exists
    if (searchTerm.trim() !== "") {
      const search = searchTerm.toLowerCase();
      filtered = filtered.filter(
        (blog) =>
          blog.title?.toLowerCase().includes(search) ||
          blog.subTitle?.toLowerCase().includes(search) ||
          blog.blogDemo?.toLowerCase().includes(search) ||
          blog.content?.toLowerCase().includes(search)
      );
    }
    
    setFilteredData(filtered);
  }, [blogs, activeCategory, searchTerm]);

  useEffect(() => {
    // Update the totalPages whenever filteredData changes
    const updatedPages = Math.ceil(filteredData.length / cardsPerPage);
    setPages(updatedPages);
    setCurrentPage(1); // Reset to the first page whenever data changes
  }, [filteredData]);

  useEffect(() => {
    if (coursesContent) {
      setFilteredCourses(
        coursesContent
          .filter(
            (post) =>
              post.blogCategory?.toLowerCase() === activeCategory.toLowerCase()
          )
          .slice(0, 4)
      );
    }
  }, [coursesContent, activeCategory]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
  };

  const handleBack = () => {
    setActiveStep(
      (prevActiveStep) => (prevActiveStep - 1 + maxSteps) % maxSteps
    );
  };

  const handleFilter = (category) => {
    setActiveCategory(category);
    setSearchTerm(""); // Clear search when changing categories
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleReadMore = (selected) => {
    const id = selected._id;
    navigate(`/blogs/${id}`);
  };
  
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  
  const handleSearchSubmit = (event) => {
    if (event.key === 'Enter') {
      // Already handled by the useEffect
    }
  };

  // const cardsPerPage = 3;
  // const totalPages = Math.ceil(filteredData?.length / cardsPerPage);
  const truncate = (text, maxLength) => {
    if (!text) return "";
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  return loading ? (
    <PreLoader />
  ) : (
    <>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          overflow: "hidden",
          position: "relative",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            transition: "transform 0.3s ease-in-out",
            transform: `translateX(-${activeStep * 100}%)`,
            width: "100%",
          }}
        >
          {trendingData?.map((data, index) => (
            <Box
              key={index}
              sx={{
                width: "100%",
                flexShrink: 0,
                p: { xs: "0 16px", md: "0 32px" },
              }}
            >
              <Box
                className="trendingBlogWrapper"
                sx={{
                  backgroundImage: `url(${data.headerImageUrl})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  height: {
                    lg: "calc(65vw * 0.5625)",
                    md: "calc(55vw * 0.5625)",
                    sm: "calc(45vw * 0.5625)",
                    xs: "calc(30vw * 0.5625)", // Reduce image height on mobile
                  },
                  width: "100%",
                  borderRadius: { xs: "12px", md: "24px" },
                  height: { xs: "200px", md: "560px" }
                }}
              >
                <Box
                  className="trendingWrapper"
                  sx={{
                    m: { md: "28px", xs: "10px" }, // Reduced margin for mobile
                    p: { md: "20px", xs: "10px" },
                    height: { xs: "50px !important", md: "160px !important" }  // Reduced padding for mobile
                  }}
                >
                  <Typography
                    className="trendingTitle"
                    sx={{
                      p: { md: "4px 16px", xs: "2px 8px" }, // Smaller padding on mobile
                      fontSize: { md: "16px", xs: "12px" },  // Smaller text on mobile
                      borderRadius: { md: "8px", xs: "4px" }, // Smaller border radius for mobile
                      fontFamily: '"Outfit", sans-serif',
                    }}
                  >
                    Trending
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { md: "24px", xs: "16px" },  // Reduced font size on mobile
                      mt: { md: "20px", xs: "8px" },        // Adjust margin for mobile
                      fontFamily: '"Outfit", sans-serif',
                    }}
                  >

                  </Typography>
                </Box>
                <Button
                  onClick={() => {
                    handleReadMore(data);
                  }}
                  className="trendingblogsImages"
                  sx={{
                    fontSize: { md: "20px", xs: "14px" }, // Reduced font size for mobile
                    m: { md: "32px 48px", xs: "12px 16px" },  // Adjusted margin for mobile
                    p: { md: "9px 16px", xs: "8px 12px" }, // Smaller padding on mobile
                    backgroundColor: "white",
                    borderRadius: "8px",
                    color: "black",
                    textTransform: "none",
                    fontFamily: '"Outfit", sans-serif',
                    "&:hover": {
                      backgroundColor: "#D3D3D3",
                      transition: "color 0.3s ease",
                      boxShadow: "none",
                    },
                  }}
                >
                  Read Blog
                  <Box
                    component="img"
                    src={blogArrow}
                    sx={{
                      width: { xs: "14px", md: "20px" },
                      pl: "5px",
                      filter: "invert(0) brightness(0)",
                    }}
                  />
                </Button>
              </Box>

            </Box>
          ))}
        </Box>
      </Paper>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            width: "100%",
            mt: { md: "20px" },
            display: "flex",
            alignItems: "center",
            maxWidth: "400px",
          }}
        >
          <Button disabled={activeStep === 0} onClick={handleBack}>
            <Box
              component="img"
              sx={{ transform: "rotate(180deg)" }}
              src={arrow}
              size="small"
            />
          </Button>
          <Box sx={{ flexGrow: 1, mx: 2 }}>
            <Box
              sx={{
                width: "100%",
                height: 8,
                backgroundColor: "#e0e0e0",
                borderRadius: "12px",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  width: `${100 / maxSteps}%`,
                  height: "100%",
                  position: "absolute",
                  backgroundColor: "#000000",
                  borderRadius: 12,
                  left: `${(100 / maxSteps) * activeStep}%`,
                  transition: "left 0.3s",
                }}
              />
            </Box>
          </Box>
          <Button disabled={activeStep === maxSteps - 1} onClick={handleNext}>
            <Box component="img" src={arrow} size="small" />
          </Button>
        </Box>
      </Box>
      <Box
        className="blogWrapper"
        sx={{
          m: {
            md: "6px auto 26px auto",
            xs: "6px",
          },
          width: { md: "80%" },
        }}
      >
        <Typography
          className="header"
          sx={{
            fontSize: { xs: "26px !important", md: "36px !important" },
            lineHeight: { xs: "30px", md: "54px" },
            marginBottom: { xs: "-20px !important" },
            marginTop: { xs: "17px !important" }
          }}
        >
          <span style={{ color: "#FF7262" }}>Explore</span> advanced concepts
          with our blogs
        </Typography>
        
        {/* Search Bar - REDUCED SPACING */}
        <Box 
          sx={{ 
            mt: 3, // Reduced from 3
            mb: 2, // Reduced from 2
            display: "flex",
            justifyContent: "center",
            width: "100%",
           
          }}
        >
          <TextField
            fullWidth
            placeholder="Search blogs by title or content..."
            value={searchTerm}
             className= "searchBar"
            onChange={handleSearchChange}
            onKeyPress={handleSearchSubmit}
            size="small" // Added small size for more compact look
            sx={{
              maxWidth: { xs: "100%", md: "600px" },
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px",
                backgroundColor: "#f7f7f7",
               
                "& fieldset": {
                  borderColor: "#949392",
                },
                "&:hover fieldset": {
                  borderColor: "#949392",
                  boxShadow: "rgba(0, 0, 0, 0.25)" // Added focus border
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#949392",
                  
                },
                
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="action" />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        
        <div className="categoryContainer">
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              overflowX: "auto",
              width: "100%",
              whiteSpace: "nowrap",
              
              marginTop: "0px !important",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {blogCategories?.map((category, index) => {
              // Count the number of blogs for each category
              const categoryCount = category === "All" 
                ? blogs.length 
                : blogs.filter(blog => blog.blogCategory === category).length;

              return (
                <Button
                  key={index}
                  sx={{
                    flexShrink: "0",
                    marginRight: "10px",
                    whiteSpace: "nowrap",
                  }}
                  className={`categoryBtns ${activeCategory === category ? "contained" : "outlined"}`}
                  onClick={() => handleFilter(category)}
                >
                  {category} ({categoryCount}) {/* Display category and its count */}
                </Button>
              );
            })}
          </Box>
        </div>

        {filteredData.length === 0 ? (
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              my: 4,
              fontSize: "20px",
              color: "#727272",
            }}
          >
            {searchTerm ? "No blogs found matching your search." : "New Blogs Coming soon!!!"}
          </Typography>
        ) : (
          <>
            {filteredData
              ?.slice(
                (currentPage - 1) * cardsPerPage,
                currentPage * cardsPerPage
              )
              .map((item, index) => (
                <Box
                  key={index}
                  className="cardWrapper"
                  sx={{
                    flexDirection: { xs: "column-reverse", md: "row" },
                    p: { sm: "20px 26px", xs: "16px" },
                  }}
                >
                 <Box
  className="contentWrapper"
  sx={{
    width: { md: "60%", xs: "100%" },
    pt: { xs: "16px", md: "0px" },
    flexDirection: "column",
    justifyContent: "space-between",
  }}
>
  <Box>
    <Typography
      className="title"
      sx={{
        color: { md: "#0F002E", xs: "#0F002E" },
        fontSize: { xs: "16px", md: "24px" },
      }}
    >
      {item.title}
    </Typography>
    <Typography
      sx={{
        color: { md: "#0F002E", xs: "#0F002E" },
        fontSize: { xs: "14px", md: "16px" },
        pb: "6px",
        fontFamily: "Outfit",
      }}
    >
      {item.subTitle}
    </Typography>
    <Typography className="date">{item.date}</Typography>
    <Typography
      className="blog"
      sx={{ fontSize: { xs: "14px", md: "16px" } }}
    >
      {truncate(item.blogDemo, 200)}
    </Typography>
  </Box>
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'left',
      mt: 1
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Button
        className="readMoreBtn"
        sx={{
          mr: 2, // Add some margin to the right of the button
        }}
        onClick={() => handleReadMore(item)}
        endIcon={<img src={leftIcon} alt="Read More Icon" />}
      >
        Read full blog
      </Button>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <VisibilityIcon sx={{ fontSize: { xs: "14px", md: "16px" }, marginRight: "4px" }} />
        <Typography sx={{ fontSize: { xs: "14px", md: "16px" }, color: "#727272" }}>
          {item.views}
        </Typography>
      </Box>
    </Box>
    {/* You can add another component here if needed on the right side */}
  </Box>
</Box>
 <Box
                    component="img"
                    src={item.headerImageUrl}
                    sx={{
                      width: { md: "35%", xs: "100%" }, // Ensure images take up consistent width
                      minWidth: { lg: "320px", md: "240px", xs: "140px" }, // Make sure the minimum width is set
                      height: "270px", // Preserve aspect ratio
                      objectFit: "cover", // Ensures the image is cropped and fills the container
                      borderRadius: "25px", // Round corners for a consistent look
                    }}
                  />
                </Box>
              ))}

            {pages > 1 && (
              <Pagination
                count={pages}
                page={currentPage}
                siblingCount={0}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
                sx={{
                  display: "flex", justifyContent: "center", width: "100%", mt: 4,
                  "& .Mui-selected": {
                    border: "1px #0F002E solid",
                    backgroundColor: "#FFFFFF !important",
                    color: "#000000 !important",
                  },
                  "& .MuiPaginationItem-root": {
                    color: "#000000",
                  },
                }}
              />
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default BlogPosts;