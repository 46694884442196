import "../mid1/mid1.scss";
import { Box, Typography } from "@mui/material";
import Image1 from "../../../assets/images/mockInterview/boy.png";
import Image2 from "../../../assets/images/mockInterview/girl.png";
import Image3 from "../../../assets/images/mockInterview/symbol1.svg";
import Image4 from "../../../assets/images/mockInterview/symbol2.svg";
import waterMark from "../../../assets/images/mockInterview/watermark-mid.svg";
import waterMark2 from "../../../assets/images/mockInterview/watermark-mid2.svg";
import correct from "../../../assets/images/mockInterview/correct-key.svg";

const Mid1 = () => (
  <Box className="mid1-container">
    <Box className="section-wrapper top-wrapper">
      <Box
        className="watermark-mi w1"
        component="img"
        src={waterMark}
      />
      <Box className="card">
        <Box className="image-container">
          <Box
            className="img-mid"
            component="img"
            src={Image1}
          />
          <Box
            className="symbol s1"
            component="img"
            src={Image3}
          />
        </Box>
        <Box className="content-container">
          <Typography className="header-text">
            Who Can Become a Coach?
          </Typography>
          <Typography className="normal-text">
            Industry Experts – Software Engineers, Data Scientists, Marketers, Financial Analysts, and more
          </Typography>
          <Box className="keys-container">
            <Box className="wrapper-key">
              <Box className="wrapper-key-inside">
                <Box component="img" src={correct} />
                <Typography className="normal-text-key">
                  Hiring Managers & Recruiters
                </Typography>
              </Box>
              <Box className="wrapper-key-inside">
                <Box component="img" src={correct} />
                <Typography className="normal-text-key">
                  Freelancers & Consultants
                </Typography>
              </Box>
            </Box>
            <Box className="wrapper-key">
              <Box className="wrapper-key-inside">
                <Box component="img" src={correct} />
                <Typography className="normal-text-key">
                  Experienced Professionals
                </Typography>
              </Box>
              <Box className="wrapper-key-inside">
                <Box component="img" src={correct} />
                <Typography className="normal-text-key">
                  Anybody with a passion
                </Typography>
              </Box>
            </Box>
          </Box>
          <Typography className="normal-text bold">
            Your expertise matters—help others while growing your career!
          </Typography>
        </Box>
      </Box>
    </Box>

    <Box className="section-wrapper">
      <Box
        className="watermark-mi"
        sx={{"left": 0, "top":0}}
        component="img"
        src={waterMark2}
      />
      <Box className="card reverse">
        <Box className="image-container">
          <Box
            className="img-mid"
            component="img"
            src={Image2}
          />
          <Box
            className="symbol s2"
            component="img"
            src={Image4}
          />
        </Box>
        <Box className="content-container">
          <Typography className="header-text">
            Practice Interview Sessions
          </Typography>
          <Typography className="normal-text">
            Don't waste countless hours searching for the right resources.
            Practice mock interviews and get personalized feedback directly
            from experienced interviewers.
          </Typography>
        </Box>
      </Box>
    </Box>
  </Box>
);

export default Mid1;