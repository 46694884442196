import React, { useState, useEffect } from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import sheetal from "../../../assets/images/testimonials/sheetal.png";
import gouthami from "../../../assets/images/testimonials/gouthami.png";
import saikiran from "../../../assets/images/testimonials/saikiran.png";
import aparna from "../../../assets/images/testimonials/aparna.jpg";
import arrow from "../../../assets/images/testimonials/arrow.svg";
import "../testimonials/Testimonials.scss";

const data = [
  {
    name: "Gauthami Kulkarni",
    image: gouthami,
    tag: "Fresher - Full Stack Developer Course",
    data: "I didn't expect to learn so much in Coursevita's Data Science course. The teachers were really good and made hard things easy to understand. The projects they gave us made me feel confident. Thanks to Coursevita, I'm ready to start working in Data Science.",
  },
  {
    name: "Sai Kiran",
    image: saikiran,
    tag: "Final Year Undergrad Student - Data Analytics Course",
    data: "Taking Coursevita's Data Analytics course was a smart move for me. It helped me understand data better, which is important for many jobs. The teachers were helpful, and the course fit well with my studies. Now, I feel ready to work as a data analyst.",
  },
  {
    name: "Aparna Ch",
    image: aparna,
    tag: "Early Career Professional - Data Science Course",
    data: "After completing Coursevita's Data Science course, I feel well-prepared and knowledgeable. The curriculum was practical and engaging, tailored to real-world scenarios. Thanks to Coursevita, I'm now proficient in data analysis and advanced techniques",
  },
  {
    name: "Sheetal S",
    image: sheetal,
    tag: "Early Career Professional - Python Course",
    data: "I learned a lot about artificial intelligence in Coursevita's Gen AI course. The mentors were smart, and the course was interesting. The projects helped me understand things better. Coursevita is great for anyone who wants to learn about AI and stay ahead in their job.",
  },
];

const Testimonials = () => {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = data.length;
  const [isPaused, setIsPaused] = useState(false);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  const isMobile = window.innerWidth < 900;

  useEffect(() => {
    let timer;
    if (!isPaused) {
      timer = setInterval(() => {
        setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
      }, 3000);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [isPaused, maxSteps]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
  };

  const handleBack = () => {
    setActiveStep(
      (prevActiveStep) => (prevActiveStep - 1 + maxSteps) % maxSteps
    );
  };

  const handleTouchStart = (event) => {
    setIsPaused(true);
    setTouchStart(event.touches[0].clientX);
  };

  const handleTouchMove = (event) => {
    setTouchEnd(event.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    setIsPaused(false);
    if (touchEnd - touchStart > 50) {
      handleBack();
    } else if (touchStart - touchEnd > 50) {
      handleNext();
    }
  };

  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  return (
    <>
      <Box
        className="testimonialsWrapper"
        sx={{
          backgroundColor: "#f4f4fe",
          flexGrow: 1,
          margin: "auto",
          textAlign: "center",
          mt: { md: "0px", xs: "0px" },
          p: { md: "30px 0", xs: "20px 0 10px 0" },
        }}
      >
        <Box className="testimonialsHeader" sx={{ pb: { md: "40px" } }}>
          <Typography
            className="headText"
            sx={{ fontSize: { xs: "20px", md: "32px" } }}
          >
            Learners adore Coursevita,
          </Typography>
          <Typography
            className="subText"
            sx={{ fontSize: { xs: "20px", md: "36px" } }}
          >
            And{" "}
            <span style={{ color: "#FE7465" }}>we cherish them dearly!</span>
          </Typography>
        </Box>
        <Paper
          square
          elevation={0}
          sx={{
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            position: "relative",
            justifyContent: "center",
            backgroundColor: "#f3f4fd",
          }}
          onMouseEnter={!isMobile ? handleMouseEnter : null}
          onMouseLeave={!isMobile ? handleMouseLeave : null}
          onTouchStart={isMobile ? handleTouchStart : null}
          onTouchMove={isMobile ? handleTouchMove : null}
          onTouchEnd={isMobile ? handleTouchEnd : null}
        >
          <Box
            sx={{
              display: "flex",
              transition: "transform 0.3s ease-in-out",
              transform: `translateX(-${activeStep * 100}%)`,
              width: { xs: "100%", sm: "80%", md: "70%" },
            }}
          >
            {data?.map((item, index) => (
              <Box
                key={index}
                sx={{ width: "100%", flexShrink: 0, padding: 2 }}
              >
                <Box className="contentWrapper" key={index}>
                  <Box
                    className="testimonialCard"
                    sx={{
                      flexDirection: { xs: "column", md: "row" },
                      borderRadius: { md: "23px", xs: "9px" },
                    }}
                  >
                    <Box
                      className="profileImg"
                      sx={{
                        width: "62px",
                        height: "62px",
                        mr: { md: "26px", xs: "0px" },
                        borderRadius: "100%",
                        alignSelf: { xs: "center", md: "flex-start" },
                      }}
                      component="img"
                      src={item.image}
                      alt={item.name}
                    />
                    <Box
                      className="cardContent"
                      sx={{ textAlign: { xs: "center", md: "left" } }}
                    >
                      <Typography
                        className="name"
                        sx={{
                          fontSize: { xs: "12px", md: "24px" },
                          mb: { md: "7px" },
                        }}
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        className="tag"
                        sx={{ fontSize: { xs: "10px", md: "20px" } }}
                      >
                        {item.tag}
                      </Typography>
                      <Typography
                        className="data"
                        sx={{
                          pt: "20px",
                          pb: { md: "20px" },
                          fontSize: { xs: "10px", md: "20px" },
                        }}
                      >
                        {item.data}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Paper>
        {!isMobile && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                width: "100%",
                mt: { md: "20px" },
                display: "flex",
                alignItems: "center",
                maxWidth: "400px",
              }}
            >
              <Button disabled={activeStep === 0} onClick={handleBack}>
                <Box
                  component="img"
                  sx={{ transform: "rotate(180deg)" }}
                  src={arrow}
                  size="small"
                />
              </Button>
              <Box sx={{ flexGrow: 1, mx: 2 }}>
                <Box
                  sx={{
                    width: "100%",
                    height: 8,
                    backgroundColor: "#e0e0e0",
                    borderRadius: "12px",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      width: `${100 / maxSteps}%`,
                      height: "100%",
                      position: "absolute",
                      backgroundColor: "#000000",
                      borderRadius: 12,
                      left: `${(100 / maxSteps) * activeStep}%`,
                      transition: "left 0.3s",
                    }}
                  />
                </Box>
              </Box>
              <Button
                disabled={activeStep === maxSteps - 1}
                onClick={handleNext}
              >
                <Box component="img" src={arrow} size="small" />
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Testimonials;
