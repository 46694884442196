import React, { useState, useRef, useEffect } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Typography, Box, Grid, Container } from "@mui/material";
import "./videoTestimonials.scss";

const VideoTestimonials = () => {
  // Video data array with local video files
  const videos = [
    {
      id: 1,
      src: "/images/student1.mp4",
    },
    {
      id: 2,
      src: "/images/student2.mp4",
    },
    {
      id: 3,
      src: "/images/student3.m4v",
    }
  ];

  // State to track which videos are playing
  const [playingVideos, setPlayingVideos] = useState({});
  const videoRefs = useRef({});
  const videoContainerRefs = useRef({});
  
  // Function to handle play button click
  const handlePlayClick = (videoId) => {
    const videoElement = videoRefs.current[videoId];
    
    // First pause all other videos
    Object.entries(videoRefs.current).forEach(([id, element]) => {
      const numId = Number(id);
      if (numId !== videoId && element && !element.paused) {
        element.pause();
        setPlayingVideos(prev => ({...prev, [numId]: false}));
      }
    });
    
    // Then handle the clicked video
    if (videoElement) {
      if (videoElement.paused) {
        videoElement.play();
        setPlayingVideos(prev => ({...prev, [videoId]: true}));
      } else {
        videoElement.pause();
        setPlayingVideos(prev => ({...prev, [videoId]: false}));
      }
    }
  };

  // Set up intersection observer to pause videos when they're out of view
  useEffect(() => {
    const observers = [];
    
    videos.forEach(video => {
      const container = videoContainerRefs.current[video.id];
      if (container) {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach(entry => {
              if (!entry.isIntersecting) {
                // Video is out of view, pause it
                const videoElement = videoRefs.current[video.id];
                if (videoElement && !videoElement.paused) {
                  videoElement.pause();
                  setPlayingVideos(prev => ({...prev, [video.id]: false}));
                }
              }
            });
          },
          { threshold: 0.3 } // Trigger when 30% of the video is out of view
        );
        
        observer.observe(container);
        observers.push(observer);
      }
    });
    
    // Clean up observers on component unmount
    return () => {
      observers.forEach(observer => observer.disconnect());
    };
  }, [videos]);

  return (
    <Container style={{marginBottom: "30px"}} maxWidth="lg" className="video-testimonials">
      <div className="testimonials-header">
      <Typography 
                variant="h4" 
                sx={{ 
                  textAlign: 'center', 
                  mb: 4,
                  fontSize: { xs: '1.5rem', md: '2rem' },
                  fontWeight: 600,
                  '& span': {
                    color: '#FF7262'
                  }
                }}
              >
          Hear the <span>Voice of Experience</span> from Our <br className="desktop-break" />
          Successful Learners
        </Typography>
      </div>

      {/* Video Grid */}
      <Grid container spacing={3} justifyContent="center" className="video-grid">
        {videos.map((video) => (
          <Grid item xs={12} sm={6} md={4} key={video.id}>
            <div
              ref={(el) => { videoContainerRefs.current[video.id] = el; }}
              className="video-container"
              onClick={() => handlePlayClick(video.id)}
            >
              <video
                ref={(el) => { 
                  videoRefs.current[video.id] = el; 
                  // Add ended event listener to show play button when video completes
                  if (el) {
                    el.addEventListener('ended', () => {
                      setPlayingVideos(prev => ({...prev, [video.id]: false}));
                    });
                  }
                }}
                className="video-element"
                preload="metadata"
              >
                <source src={video.src} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              
              {/* Custom play button */}
              {!playingVideos[video.id] && (
                <div className="play-button">
                  <PlayArrowIcon className="play-icon" />
                </div>
              )}
            </div>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default VideoTestimonials;