/**
 * Utility functions for tracking course interactions
 */

// Set a cookie with a specified name, value and expiration days
const setCookie = (name, value, days = 30) => {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/;SameSite=Lax`;
};

// Get a cookie value by name
const getCookie = (name) => {
  const cookieName = `${name}=`;
  const cookies = document.cookie.split(';');
  
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i].trim();
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return "";
};

// Track a course click
export const trackCourseClick = (courseName, actionType) => {
  // Get existing clicked courses or initialize empty array
  let clickedCourses = getCookie('clickedCourses');
  let coursesArray = [];
  
  if (clickedCourses) {
    try {
      coursesArray = JSON.parse(clickedCourses);
    } catch (e) {
      console.error('Error parsing clicked courses cookie', e);
    }
  }
  
  // Add new course click with timestamp
  coursesArray.push({
    course: courseName,
    action: actionType,
    timestamp: new Date().toISOString()
  });
  
  // Keep only the last 20 clicks to avoid large cookies
  if (coursesArray.length > 20) {
    coursesArray = coursesArray.slice(-20);
  }
  
  // Save updated clicks
  setCookie('clickedCourses', JSON.stringify(coursesArray));
};