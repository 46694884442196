import React from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import GrowthArrow from "../../../assets/images/coursePreview/GrowthArrow.svg";
import bulb from "../../../assets/images/coursePreview/bulb.svg";
import project from "../../../assets/images/coursePreview/project.svg";
import job from "../../../assets/images/coursePreview/job.svg"; 
import development from "../../../assets/images/coursePreview/development.svg";

const learningPathData = [
  {
    icon: GrowthArrow,
    title: "Expert-Led Training for career growth",
  },
  {
    icon: bulb,
    title: "Engage in Practical Assignments",
  },
  {
    icon: project,
    title: "Hands-on experience with Real-World Projects",
  },
  {
    icon: job,
    title: "100% Placement Job Guidance ",
  },
  {
    icon: development,
    title: "Unlock your potential",
  },
];

const LearningPathSection = () => {
  return (
    <Box sx={{ py: { xs: 4, md: 6 }, px: { xs: 2, md: 4 } }}>
      <Typography
        variant="h2"
        align="center"
        sx={{
          fontSize: { xs: '24px', md: '32px' },
          mb: 4,
          fontWeight: '600',
        }}
      >
        <Box component="span" sx={{ color: '#FE7465' }}>Learning</Box>
        {' Path'}
      </Typography>

      <Grid container spacing={3} justifyContent="center">
        {learningPathData.map((item, index) => (
          <Grid item xs={12} sm={6} md={2.4} key={index}>
            <Card
              elevation={0}
              sx={{
                height: '100%',
                bgcolor: 'transparent',
                border: 'none',
                boxShadow: 'none',
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  p: 2,
                }}
              >
                <Box
                  component="img"
                  src={item.icon}
                  alt={item.title}
                  sx={{
                    width: '48px',
                    height: '48px',
                    mb: 2,
                    color: '#6C63FF',
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: { xs: '14px', md: '16px' },
                    fontWeight: 500,
                    lineHeight: 1.4,
                  }}
                >
                  {item.title}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default LearningPathSection;