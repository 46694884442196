import {
  Box,
  Button,
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Select
} from "@mui/material";
import Cancel from "../../../../assets/images/coursePreview/Cancel.svg";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import "./mockinterviewform.scss";

function MockInterview(props) {
  const navigate = useNavigate();
  const recaptchaRef = useRef();

  const { handleCloseDialog, courseContent, courseName } = props;
  const [name, setName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [mobileNum, setMobileNum] = useState("");
  const [message, setMessage] = useState("");
  const [currentRole, setCurrentRole] = useState("");
  const [experience, setExperience] = useState("");
  const [skills, setSkills] = useState([]);
  const [conductedBefore, setconductedBefore] = useState("");
  const [mentorshipMode, setMentorshipMode] = useState("");
  const [availableTime, setAvailableTime] = useState("");
  const [mentorReason, setMentorReason] = useState("");
  const [loading, setLoading] = useState(false);

  const skillOptions = ["Tech Interview", "Bussiness Roles", "Resume Review"];

  const handleSkillChange = (event) => {
    const value = event.target.value;
    setSkills((prevSkills) =>
      prevSkills.includes(value)
        ? prevSkills.filter((skill) => skill !== value)
        : [...prevSkills, value]
    );
  };

  const getPageName = async () => {
    const { hostname, pathname } = window.location;
    const siteName = hostname.split('.')[0].charAt(0).toUpperCase() + hostname.split('.')[0].slice(1);
    const pathSegments = pathname.split('/').filter(Boolean);

    if (pathSegments.length === 0) {
      return `The user clicked on the ${siteName} Main Page for downloading curriculum`;
    }

    const pageDescriptions = pathSegments.map(segment => {
      if (segment && typeof segment === 'string') {
        return segment.replace(/-/g, ' ').charAt(0).toUpperCase() + segment.slice(1) + " Page";
      }
      return "";
    }).filter(Boolean);

    return `The user clicked through to the ${siteName} ${pageDescriptions.join(' -> ')} for downloading curriculum`;
  };



  const product = `Mock Interviews and Resume Reviews`
  const handleDownload = async () => {
    const currentUrl = window.location.href;
    const pageDescription = await getPageName();
    setLoading(true);
    console.log(name,
      emailId,
      mobileNum,
      product,
      currentRole,
      currentUrl,
      pageDescription,
      skills,
      experience,
      mentorshipMode, conductedBefore,
      availableTime,
      mentorReason)

    const leadData = {
      emailId: emailId,
      mobileNum: mobileNum,
      product: product,
      currentRole: currentRole,
      currentUrl: currentUrl,
      pageDescription: pageDescription,
      skills: skills,
      experience: experience,
      mentorshipMode: mentorshipMode,
      conductedBefore: conductedBefore,
      availableTime: availableTime,
      mentorReason: mentorReason
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_LINK}/coachvita-leads`, leadData);
      handleCloseDialog();
      setTimeout(() => {
        setMessage("");
      }, 3000);
    } catch (error) {
      console.error("Error subscribing:", error.response?.data?.message || error.message);
      setMessage(error.response?.data.message);
    } finally {
      setLoading(false);
    }
  };

  const validation = () => {
    const mobileNumRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (isEmpty(name)) {
      setMessage("Please enter Full name");
      setLoading(false);
    } else if (isEmpty(emailId) || !emailRegex.test(emailId)) {
      setMessage("Please enter valid Email Id");
      return false;
    } else if (isEmpty(mobileNum) || mobileNum.length !== 10 || !mobileNumRegex.test(mobileNum)) {
      setMessage("Please enter valid mobile number");
      return false;
    } else {
      setMessage("");
      handleDownload();
      return true;
    }
  };

  return (
    <Box sx={{ width: "100%" }} className="curriculumWrapper">
      <Box
        className="curriculumContent"
        sx={{
          borderRadius: { md: "25px 25px 0px 0px", xs: "16px" },
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Optional shadow for a soft effect
        }}
      >
        <Box
          component="img"
          src={Cancel}
          sx={{ p: "16px 16px 0px", cursor: "pointer", borderRadius: "12px" }}
          onClick={handleCloseDialog}
        />
        <Box
          className="contentform"
          sx={{ p: { xs: "0 16px", md: "0 48px 32px" }, mt: "-10px" }}
        >
          <Typography className="curriculumHeader" sx={{ mb: "4px" }}>
            Mock Interviews and Resume Reviews
          </Typography>
          <Typography className="curriculumSubHeader">
            Please fill out the form.
          </Typography>

          <Box className="formInput">
            <Typography className="formInputHeader">Full Name*</Typography>
            <TextField
              id="outlined-basic"
              value={name}
              onChange={(e) => setName(e.target.value)}
              variant="outlined"
              className="formText"
              sx={{ borderRadius: "16px" }}
            />
          </Box>

          <Box className="formInput">
            <Typography className="formInputHeader">Email ID*</Typography>
            <TextField
              id="outlined-basic"
              value={emailId}
              onChange={(e) => setEmailId(e.target.value)}
              variant="outlined"
              fullWidth
              className="formText"
              sx={{ borderRadius: "16px" }}
            />
          </Box>

          <Box className="formInput">
            <Typography className="formInputHeader">Mobile Number*</Typography>
            <TextField
              id="outlined-basic"
              value={mobileNum}
              onChange={(e) => setMobileNum(e.target.value)}
              variant="outlined"
              className="formText"
              sx={{ borderRadius: "16px" }}
            />
          </Box>
          <Box className="formInput">
            <Typography className="formInputHeader">Current Role & Company*</Typography>
            <TextField
              id="outlined-basic"
              value={currentRole}
              onChange={(e) => setCurrentRole(e.target.value)}
              variant="outlined"
              className="formText"
              sx={{ borderRadius: "16px" }}
            />
          </Box>

          <Box className="formInput">
            <Typography className="formInputHeader">Years of Experience</Typography>
            <TextField
              value={experience}
              onChange={(e) => setExperience(e.target.value)}
              variant="outlined"
              className="formText"
              select
              sx={{
                borderRadius: "16px",
                "& .MuiSelect-select": {
                  padding: "6px 10px", // reduced padding
                  height: "40px", // reduced height
                },
                "& .MuiInputBase-root": {
                  height: "40px", // adjusted height of container
                }
              }}
            >
              <MenuItem value="Less than 1 year">Less than 1 year</MenuItem>
              <MenuItem value="1-3 years">1-3 years</MenuItem>
              <MenuItem value="3-5 years">3-5 years</MenuItem>
              <MenuItem value="5+ years">5+ years</MenuItem>
            </TextField>
          </Box>

          <Box className="formInput">
            <Typography className="formInputHeader">Expertise In:</Typography>
            <FormGroup sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 1 }}>
              {skillOptions.map((skill) => (
                <FormControlLabel
                  key={skill}
                  control={
                    <Checkbox
                      checked={skills.includes(skill)}
                      onChange={handleSkillChange}
                      value={skill}
                      sx={{
                        "& .MuiCheckbox-root": {
                          transform: "scale(0.6)",
                          color: "#b0bec5", // lighter color for checkbox
                        },
                        "& .MuiFormControlLabel-label": {
                          fontSize: "10px", // even smaller text
                          color: "#b0bec5", // lighter color for label
                        }
                      }}
                    />
                  }
                  label={skill}
                />
              ))}
            </FormGroup>
          </Box>

          <Box className="formInput">
            <Typography className="formInputHeader">Preferred Mode</Typography>
            <TextField
              value={mentorshipMode}
              onChange={(e) => setMentorshipMode(e.target.value)}
              variant="outlined"
              className="formText"
              select
              sx={{
                borderRadius: "16px",
                "& .MuiSelect-select": {
                  padding: "6px 10px", // reduced padding
                  height: "40px", // reduced height
                },
                "& .MuiInputBase-root": {
                  height: "40px", // adjusted height of container
                }
              }}
            >
              <MenuItem value="Online 1:1">Online 1:1</MenuItem>
              <MenuItem value="Group Mentorship">Group Mentorship</MenuItem>
              <MenuItem value="Both">Both</MenuItem>
            </TextField>
          </Box>

          <Box className="formInput">
            <Typography className="formInputHeader">Have You Conducted Interviews Before?</Typography>
            <TextField
              value={conductedBefore}
              onChange={(e) => setconductedBefore(e.target.value)}
              // variant="outlined"
              className="formText"
              select
              sx={{
                borderRadius: "16px",
                "& .MuiSelect-select": {
                  padding: "6px 10px", // reduced padding
                  height: "40px", // reduced height
                },
                "& .MuiInputBase-root": {
                  height: "40px", // adjusted height of container
                }
              }}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </TextField>
          </Box>


          <Box className="formInput">
            <Typography className="formInputHeader">Available Time Slots</Typography>
            <TextField
              value={availableTime}
              onChange={(e) => setAvailableTime(e.target.value)}
              // variant="outlined"
              className="formText"
              select
              sx={{
                borderRadius: "16px",
                "& .MuiSelect-select": {
                  padding: "6px 10px", // reduced padding
                  height: "40px", // reduced height
                },
                "& .MuiInputBase-root": {
                  height: "40px", // adjusted height of container
                }
              }}
            >
              <MenuItem value="Weekdays">Weekdays</MenuItem>
              <MenuItem value="Weekends">Weekends</MenuItem>
              <MenuItem value="Flexible">Flexible</MenuItem>
            </TextField>
          </Box>

          <Box className="formInput">
            <Typography className="formInputHeader">Why Do You Want to Mentor? (Optional)</Typography>
            <TextField value={mentorReason} onChange={(e) => setMentorReason(e.target.value)} className="formText" sx={{ borderRadius: "16px" }} />
          </Box>

          {message && (
            <Typography className="freeTagline" sx={{ color: "red" }}>
              {message}
            </Typography>
          )}

          <Button
            variant="contained"
            className="contactButton"
            onClick={validation}
            disabled={loading}
          >
            <span style={{ color: "white" }}>
              {loading ? "Applied..." : "Apply"}
            </span>
          </Button>

          <Typography className="tagline" sx={{ paddingBottom: { xs: '16px', md: 0 } }}>
            By filling this form, you agree to our{" "}
            <a className="terms" href="/terms">
              Terms and conditions
            </a>{" "}
            and you agree to receive communication from us.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default MockInterview;
