import {
    Box,
    Button,
    TextField,
    Typography,
    FormGroup,
    FormControlLabel,
    Checkbox,
    MenuItem,
    Select
} from "@mui/material";
import Cancel from "../../../../assets/images/coursePreview/Cancel.svg";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import "./onetoone.scss";

function Onetoone(props) {
    const navigate = useNavigate();
    const recaptchaRef = useRef();

    const { handleCloseDialog, courseContent, courseName } = props;
    const [name, setName] = useState("");
    const [emailId, setEmailId] = useState("");
    const [mobileNum, setMobileNum] = useState("");
    const [message, setMessage] = useState("");
    const [currentRole, setCurrentRole] = useState("");
    const [experience, setExperience] = useState("");
    const [skills, setSkills] = useState([]);
    const [mentorshipMode, setMentorshipMode] = useState("");
    const [availableTime, setAvailableTime] = useState("");
    const [mentorReason, setMentorReason] = useState("");
    const [loading, setLoading] = useState(false);

    const skillOptions = ["Data Science", "AI/ML", "Business Analytics", "Career Guidance", "Resume Building", "Interview Preparation", "Other"];

    const handleSkillChange = (event) => {
        const value = event.target.value;
        setSkills((prevSkills) =>
            prevSkills.includes(value)
                ? prevSkills.filter((skill) => skill !== value)
                : [...prevSkills, value]
        );
    };

    const getPageName = async () => {
        const { hostname, pathname } = window.location;
        const siteName = hostname.split('.')[0].charAt(0).toUpperCase() + hostname.split('.')[0].slice(1);
        const pathSegments = pathname.split('/').filter(Boolean);

        if (pathSegments.length === 0) {
            return `The user clicked on the ${siteName} Main Page for downloading curriculum`;
        }

        const pageDescriptions = pathSegments.map(segment => {
            if (segment && typeof segment === 'string') {
                return segment.replace(/-/g, ' ').charAt(0).toUpperCase() + segment.slice(1) + " Page";
            }
            return "";
        }).filter(Boolean);

        return `The user clicked through to the ${siteName} ${pageDescriptions.join(' -> ')} for downloading curriculum`;
    };
    const product =`1-1 Counselling`
    const handleDownload = async () => {
        const currentUrl = window.location.href;
        const pageDescription = await getPageName();
        setLoading(true);
        const conductedBefore="to be noted"
        console.log(name,
            emailId,
            mobileNum,
            product,
            currentRole,
            currentUrl,
            pageDescription,
            skills,
            experience,
            mentorshipMode,
            availableTime,
            mentorReason)

            const leadData = {
                emailId: emailId,
                mobileNum: mobileNum,
                product: product,
                currentRole: currentRole,
                currentUrl: currentUrl,
                pageDescription: pageDescription,
                skills: skills,
                experience: experience,
                mentorshipMode: mentorshipMode,
                conductedBefore: conductedBefore,
                availableTime: availableTime,
                mentorReason: mentorReason
              };
          
              try {
                await axios.post(
                  `${process.env.REACT_APP_BACKEND_LINK}/coachvita-leads`, leadData);
                handleCloseDialog();
                setTimeout(() => {
                  setMessage("");
                }, 3000);
              } catch (error) {
                console.error("Error subscribing:", error.response?.data?.message || error.message);
                setMessage(error.response?.data.message);
              } finally {
                setLoading(false);
              }
        
        // try {
        //     await axios.post(
        //         `${process.env.REACT_APP_BACKEND_LINK}/api/downloadCurriculum`,
        //         {
        //             fullName: name,
        //             emailId: emailId,
        //             number: mobileNum,
        //             course: props.courseContent.course,
        //             captchaToken: recaptchaRef.current.getValue(),
        //             currerntRole:currentRole
        //             currentUrl: currentUrl,
        //             pageDescription: pageDescription,
        //             skills: skills,
        //             experience: experience,
        //             mentorshipMode: mentorshipMode,
        //             availableTime: availableTime,
        //             mentorReason: mentorReason
        //         }
        //     );
        //     setName("");
        //     setEmailId("");
        //     setMobileNum("");
        //     handleCloseDialog();
        //     setTimeout(() => {
        //         setMessage("");
        //     }, 3000);
        // } catch (error) {
        //     console.error("Error subscribing:", error.response?.data?.message || error.message);
        //     setMessage(error.response?.data.message);
        // } finally {
        //     setLoading(false);
        // }
    };

    const validation = () => {
        const mobileNumRegex = /^\d{10}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (isEmpty(name)) {
            setMessage("Please enter Full name");
            setLoading(false);
        } else if (isEmpty(emailId) || !emailRegex.test(emailId)) {
            setMessage("Please enter valid Email Id");
            return false;
        } else if (isEmpty(mobileNum) || mobileNum.length !== 10 || !mobileNumRegex.test(mobileNum)) {
            setMessage("Please enter valid mobile number");
            return false;
        } else {
            setMessage("");
            handleDownload();
            return true;
        }
    };

    return (
        <Box sx={{ width: "100%" }} className="curriculumWrapper">
            <Box
                className="curriculumContent"
                sx={{
                    borderRadius: { md: "25px 25px 0px 0px", xs: "16px" },
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Optional shadow for a soft effect
                }}
            >
                <Box
                    component="img"
                    src={Cancel}
                    sx={{ p: "16px 16px 0px", cursor: "pointer", borderRadius: "12px" }}
                    onClick={handleCloseDialog}
                />
                <Box
                    className="contentform"
                    sx={{ p: { xs: "0 16px", md: "0 48px 32px" }, mt: "-10px" }}
                >
                    <Typography className="curriculumHeader" sx={{ mb: "4px" }}>
                        1-1 Career Counselling
                    </Typography>
                    <Typography className="curriculumSubHeader">
                        Please fill out the form, and register for 1-1 Career Counselling.
                    </Typography>

                    <Box className="formInput">
                        <Typography className="formInputHeader">Full Name*</Typography>
                        <TextField
                            id="outlined-basic"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            variant="outlined"
                            className="formText"
                            sx={{ borderRadius: "16px" }}
                        />
                    </Box>

                    <Box className="formInput">
                        <Typography className="formInputHeader">Email ID*</Typography>
                        <TextField
                            id="outlined-basic"
                            value={emailId}
                            onChange={(e) => setEmailId(e.target.value)}
                            variant="outlined"
                            fullWidth
                            className="formText"
                            sx={{ borderRadius: "16px" }}
                        />
                    </Box>

                    <Box className="formInput">
                        <Typography className="formInputHeader">Mobile Number*</Typography>
                        <TextField
                            id="outlined-basic"
                            value={mobileNum}
                            onChange={(e) => setMobileNum(e.target.value)}
                            variant="outlined"
                            className="formText"
                            sx={{ borderRadius: "16px" }}
                        />
                    </Box>
                    <Box className="formInput">
                        <Typography className="formInputHeader">Current Role*</Typography>
                        <TextField
                            id="outlined-basic"
                            value={currentRole}
                            onChange={(e) => setCurrentRole(e.target.value)}
                            variant="outlined"
                            className="formText"
                            sx={{ borderRadius: "16px" }}
                        />
                    </Box>

                    <Box className="formInput">
                        <Typography className="formInputHeader">Years of Experience</Typography>
                        <TextField
                            value={experience}
                            onChange={(e) => setExperience(e.target.value)}
                            variant="outlined"
                            className="formText"
                            select
                            sx={{
                                borderRadius: "16px",
                                "& .MuiSelect-select": {
                                    padding: "6px 10px", // reduced padding
                                    height: "40px", // reduced height
                                },
                                "& .MuiInputBase-root": {
                                    height: "40px", // adjusted height of container
                                }
                            }}
                        >
                            <MenuItem value="Less than 1 year">Less than 1 year</MenuItem>
                            <MenuItem value="1-3 years">1-3 years</MenuItem>
                            <MenuItem value="3-5 years">3-5 years</MenuItem>
                            <MenuItem value="5+ years">5+ years</MenuItem>
                        </TextField>
                    </Box>

                    <Box className="formInput">
                        <Typography className="formInputHeader">Skills You Can Mentor In</Typography>
                        <FormGroup sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 1 }}>
                            {skillOptions.map((skill) => (
                                <FormControlLabel
                                    key={skill}
                                    control={
                                        <Checkbox
                                            checked={skills.includes(skill)}
                                            onChange={handleSkillChange}
                                            value={skill}
                                            sx={{
                                                "& .MuiCheckbox-root": {
                                                    transform: "scale(0.6)",
                                                    color: "#b0bec5", // lighter color for checkbox
                                                },
                                                "& .MuiFormControlLabel-label": {
                                                    fontSize: "10px", // even smaller text
                                                    color: "#b0bec5", // lighter color for label
                                                }
                                            }}
                                        />
                                    }
                                    label={skill}
                                />
                            ))}
                        </FormGroup>
                    </Box>

                    <Box className="formInput">
                        <Typography className="formInputHeader">Preferred Mentorship Mode</Typography>
                        <TextField
                            value={mentorshipMode}
                            onChange={(e) => setMentorshipMode(e.target.value)}
                            variant="outlined"
                            className="formText"
                            select
                            sx={{
                                borderRadius: "16px",
                                "& .MuiSelect-select": {
                                    padding: "6px 10px", // reduced padding
                                    height: "40px", // reduced height
                                },
                                "& .MuiInputBase-root": {
                                    height: "40px", // adjusted height of container
                                }
                            }}
                        >
                            <MenuItem value="Online 1:1">Online 1:1</MenuItem>
                            <MenuItem value="Group Mentorship">Group Mentorship</MenuItem>
                            <MenuItem value="Both">Both</MenuItem>
                        </TextField>
                    </Box>

                    <Box className="formInput">
                        <Typography className="formInputHeader">Available Time Slots</Typography>
                        <TextField
                            value={availableTime}
                            onChange={(e) => setAvailableTime(e.target.value)}
                            // variant="outlined"
                            className="formText"
                            select
                            sx={{
                                borderRadius: "16px",
                                "& .MuiSelect-select": {
                                    padding: "6px 10px", // reduced padding
                                    height: "40px", // reduced height
                                },
                                "& .MuiInputBase-root": {
                                    height: "40px", // adjusted height of container
                                }
                            }}
                        >
                            <MenuItem value="Weekdays">Weekdays</MenuItem>
                            <MenuItem value="Weekends">Weekends</MenuItem>
                            <MenuItem value="Flexible">Flexible</MenuItem>
                        </TextField>
                    </Box>

                    <Box className="formInput">
                        <Typography className="formInputHeader">Why Do You Want to Mentor? (Optional)</Typography>
                        <TextField value={mentorReason} onChange={(e) => setMentorReason(e.target.value)} className="formText" sx={{ borderRadius: "16px" }} />
                    </Box>

                    {message && (
                        <Typography className="freeTagline" sx={{ color: "red" }}>
                            {message}
                        </Typography>
                    )}

                    <Button
                        variant="contained"
                        className="contactButton"
                        onClick={validation}
                        disabled={loading}
                    >
                        <span style={{ color: "white" }}>
                            {loading ? "Applied..." : "Apply"}
                        </span>
                    </Button>

                    <Typography className="tagline" sx={{ paddingBottom: { xs: '16px', md: 0 } }}>
                        By filling this form, you agree to our{" "}
                        <a className="terms" href="/terms">
                            Terms and conditions
                        </a>{" "}
                        and you agree to receive communication from us.
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default Onetoone;

// import {
//     Box,
//     Button,
//     TextField,
//     Typography,
//     FormGroup,
//     FormControlLabel,
//     Checkbox
// } from "@mui/material";
// import Cancel from "../../../../assets/images/coursePreview/Cancel.svg";
// import React, { useRef, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { isEmpty } from "lodash";
// import axios from "axios";
// import ReCAPTCHA from "react-google-recaptcha";
// import "./onetoone.scss";

// function Onetoone(props) {
//     const navigate = useNavigate();
//     const recaptchaRef = useRef();

//     const { handleCloseDialog, courseContent, courseName } = props;
//     const [name, setName] = useState("");
//     const [emailId, setEmailId] = useState("");
//     const [mobileNum, setMobileNum] = useState("");
//     const [message, setMessage] = useState("");
//     const [currentRole, setCurrentRole] = useState("");
//     const [experience, setExperience] = useState("");
//     const [skills, setSkills] = useState([]);
//     const [mentorshipMode, setMentorshipMode] = useState("");
//     const [availableTime, setAvailableTime] = useState("");
//     const [mentorReason, setMentorReason] = useState("");
//     const [loading, setLoading] = useState(false);

//     const skillOptions = ["Data Science", "AI/ML", "Business Analytics", "Career Guidance", "Resume Building", "Interview Preparation", "Other"];

//     const handleSkillChange = (event) => {
//         const value = event.target.value;
//         setSkills((prevSkills) =>
//             prevSkills.includes(value)
//                 ? prevSkills.filter((skill) => skill !== value)
//                 : [...prevSkills, value]
//         );
//     };

//     const getPageName = async () => {
//         const { hostname, pathname } = window.location;
//         const siteName = hostname.split('.')[0].charAt(0).toUpperCase() + hostname.split('.')[0].slice(1);
//         const pathSegments = pathname.split('/').filter(Boolean);

//         if (pathSegments.length === 0) {
//             return `The user clicked on the ${siteName} Main Page for downloading curriculum`;
//         }

//         const pageDescriptions = pathSegments.map(segment => {
//             if (segment && typeof segment === 'string') {
//                 return segment.replace(/-/g, ' ').charAt(0).toUpperCase() + segment.slice(1) + " Page";
//             }
//             return "";
//         }).filter(Boolean);

//         return `The user clicked through to the ${siteName} ${pageDescriptions.join(' -> ')} for downloading curriculum`;
//     };

//     const handleDownload = async () => {
//         const currentUrl = window.location.href;
//         const pageDescription = await getPageName();
//         setLoading(true);
//         console.log(name,
//             emailId,
//             mobileNum,
//             props.courseContent.course,
//             currentUrl,
//             pageDescription,
//             skills,
//             experience,
//             mentorshipMode,
//             availableTime,
//             mentorReason)
//         // try {
//         //     await axios.post(
//         //         `${process.env.REACT_APP_BACKEND_LINK}/api/downloadCurriculum`,
//         //         {
//         //             fullName: name,
//         //             emailId: emailId,
//         //             number: mobileNum,
//         //             course: props.courseContent.course,
//         //             captchaToken: recaptchaRef.current.getValue(),
//         //             currentUrl: currentUrl,
//         //             pageDescription: pageDescription,
//         //             skills: skills,
//         //             experience: experience,
//         //             mentorshipMode: mentorshipMode,
//         //             availableTime: availableTime,
//         //             mentorReason: mentorReason
//         //         }
//         //     );
//         //     setName("");
//         //     setEmailId("");
//         //     setMobileNum("");
//         //     handleCloseDialog();
//         //     setTimeout(() => {
//         //         setMessage("");
//         //     }, 3000);
//         // } catch (error) {
//         //     console.error("Error subscribing:", error.response?.data?.message || error.message);
//         //     setMessage(error.response?.data.message);
//         // } finally {
//         //     setLoading(false);
//         // }
//     };

//     const validation = () => {
//         const mobileNumRegex = /^\d{10}$/;
//         const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//         if (isEmpty(name)) {
//             setMessage("Please enter Full name");
//             setLoading(false);
//         } else if (isEmpty(emailId) || !emailRegex.test(emailId)) {
//             setMessage("Please enter valid Email Id");
//             return false;
//         } else if (isEmpty(mobileNum) || mobileNum.length !== 10 || !mobileNumRegex.test(mobileNum)) {
//             setMessage("Please enter valid mobile number");
//             return false;
//         } else {
//             setMessage("");
//             handleDownload();
//             return true;
//         }
//     };

//     return (
//         <Box sx={{ width: "100%" }} className="curriculumWrapper">
//             <Box
//                 className="curriculumContent"
//                 sx={{
//                     borderRadius: { md: "25px 25px 0px 0px", xs: "16px" },
//                     boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Optional shadow for a soft effect
//                 }}
//             >
//                 <Box
//                     component="img"
//                     src={Cancel}
//                     sx={{ p: "16px 16px 0px", cursor: "pointer", borderRadius: "12px" }}
//                     onClick={handleCloseDialog}
//                 />
//                 <Box
//                     className="contentform"
//                     sx={{ p: { xs: "0 16px", md: "0 48px 32px" }, mt: "-10px" }}
//                 >
//                     <Typography className="curriculumHeader" sx={{ mb: "4px" }}>
//                         1-1 Career Counselling
//                     </Typography>
//                     <Typography className="curriculumSubHeader">
//                         Please fill out the form, and register for 1-1 Career Couselling.
//                     </Typography>

//                     <Box className="formInput">
//                         <Typography className="formInputHeader">Full Name*</Typography>
//                         <TextField
//                             id="outlined-basic"
//                             value={name}
//                             onChange={(e) => setName(e.target.value)}
//                             variant="outlined"
//                             className="formText"
//                             sx={{ borderRadius: "16px" }}
//                         />
//                     </Box>

//                     <Box className="formInput">
//                         <Typography className="formInputHeader">Email ID*</Typography>
//                         <TextField
//                             id="outlined-basic"
//                             value={emailId}
//                             onChange={(e) => setEmailId(e.target.value)}
//                             variant="outlined"
//                             fullWidth
//                             className="formText"
//                             sx={{ borderRadius: "16px" }}
//                         />
//                     </Box>

//                     <Box className="formInput">
//                         <Typography className="formInputHeader">Mobile Number*</Typography>
//                         <TextField
//                             id="outlined-basic"
//                             value={mobileNum}
//                             onChange={(e) => setMobileNum(e.target.value)}
//                             variant="outlined"
//                             className="formText"
//                             sx={{ borderRadius: "16px" }}
//                         />
//                     </Box>

//                     <Box className="formInput">
//                         <Typography className="formInputHeader">Years of Experience</Typography>
//                         <TextField
//                             value={experience}
//                             onChange={(e) => setExperience(e.target.value)}
//                             variant="outlined"
//                             className="formText"
//                             placeholder="Enter your experience"
//                             sx={{ borderRadius: "16px" }}
//                         />
//                     </Box>

//                     <Box className="formInput">
//                         <Typography className="formInputHeader">Skills You Can Mentor In</Typography>
//                         <FormGroup sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 1 }}>
//                             {skillOptions.map((skill) => (
//                                 <FormControlLabel
//                                     key={skill}
//                                     control={
//                                         <Checkbox
//                                             checked={skills.includes(skill)}
//                                             onChange={handleSkillChange}
//                                             value={skill}
//                                             sx={{
//                                                 "& .MuiCheckbox-root": {
//                                                     transform: "scale(0.6)",
//                                                     color: "#b0bec5", // lighter color for checkbox
//                                                 },
//                                                 "& .MuiFormControlLabel-label": {
//                                                     fontSize: "10px", // even smaller text
//                                                     color: "#b0bec5", // lighter color for label
//                                                 }
//                                             }}
//                                         />
//                                     }
//                                     label={skill}
//                                 />
//                             ))}
//                         </FormGroup>
//                     </Box>

//                     <Box className="formInput">
//                         <Typography className="formInputHeader">Preferred Mentorship Mode</Typography>
//                         <TextField
//                             value={mentorshipMode}
//                             onChange={(e) => setMentorshipMode(e.target.value)}
//                             variant="outlined"
//                             className="formText"
//                             placeholder="Enter mentorship mode"
//                             sx={{ borderRadius: "16px" }}
//                         />
//                     </Box>

//                     <Box className="formInput">
//                         <Typography className="formInputHeader">Available Time Slots</Typography>
//                         <TextField
//                             value={availableTime}
//                             onChange={(e) => setAvailableTime(e.target.value)}
//                             variant="outlined"
//                             className="formText"
//                             placeholder="Enter your availability"
//                             sx={{ borderRadius: "16px" }}
//                         />
//                     </Box>

//                     <Box className="formInput">
//                         <Typography className="formInputHeader">Why Do You Want to Mentor? (Optional)</Typography>
//                         <TextField value={mentorReason} onChange={(e) => setMentorReason(e.target.value)} className="formText" sx={{ borderRadius: "16px" }} />
//                     </Box>

//                     {message && (
//                         <Typography className="freeTagline" sx={{ color: "red" }}>
//                             {message}
//                         </Typography>
//                     )}


//                     <Button
//                         variant="contained"
//                         className="contactButton"
//                         onClick={validation}
//                         disabled={loading}
//                     >
//                         <span style={{ color: "white" }}>
//                             {loading ? "Downloading..." : "Download"}
//                         </span>
//                     </Button>

//                     <Typography className="tagline" sx={{ paddingBottom: { xs: '16px', md: 0 } }}>
//                         By filling this form, you agree to our{" "}
//                         <a className="terms" href="/terms">
//                             Terms and conditions
//                         </a>{" "}
//                         and you agree to receive communication from us.
//                     </Typography>
//                 </Box>
//             </Box>
//         </Box>
//     );
// }

// export default Onetoone;






// import {
//     Box,
//     Button,
//     TextField,
//     Typography,
//     MenuItem,
//     Checkbox,
//     FormControlLabel,
//     FormGroup,
//     Select
// } from "@mui/material";
// import Cancel from "../../../../assets/images/coursePreview/Cancel.svg";
// import React, { useRef, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { isEmpty } from "lodash";
// import axios from "axios";
// import ReCAPTCHA from "react-google-recaptcha";
// import "./mockinterviewform.scss";

// function Onetoone(props) {
//     const navigate = useNavigate();
//     const recaptchaRef = useRef();

//     const { handleCloseDialog, courseContent } = props;
//     const [name, setName] = useState("");
//     const [emailId, setEmailId] = useState("");
//     const [mobileNum, setMobileNum] = useState("");
//     const [currentRole, setCurrentRole] = useState("");
//     const [experience, setExperience] = useState("");
//     const [skills, setSkills] = useState([]);
//     const [mentorshipMode, setMentorshipMode] = useState("");
//     const [availableTime, setAvailableTime] = useState("");
//     const [mentorReason, setMentorReason] = useState("");
//     const [message, setMessage] = useState("");
//     const [loading, setLoading] = useState(false);

//     const skillOptions = ["Data Science", "AI/ML", "Business Analytics", "Career Guidance", "Resume Building", "Interview Preparation", "Other"];

//     const handleSkillChange = (event) => {
//         const value = event.target.value;
//         setSkills(typeof value === "string" ? value.split(",") : value);
//     };

//     return (
//         <Box className="curriculumWrapper">
//             <Box className="curriculumContent">
//                 <Box component="img" src={Cancel} className="closeIcon" onClick={handleCloseDialog} />
//                 <Box className="contentform">
//                     <Typography className="curriculumHeader">Download the curriculum today!</Typography>
//                     <Typography className="curriculumSubHeader">Please fill out the form, and download the curriculum.</Typography>

//                     <Box className="formInput">
//                         <Typography className="formInputHeader">Full Name*</Typography>
//                         <TextField value={name} onChange={(e) => setName(e.target.value)} className="formText" />
//                     </Box>

//                     <Box className="formInput">
//                         <Typography className="formInputHeader">Email ID*</Typography>
//                         <TextField value={emailId} onChange={(e) => setEmailId(e.target.value)} className="formText" />
//                     </Box>

//                     <Box className="formInput">
//                         <Typography className="formInputHeader">Mobile Number*</Typography>
//                         <TextField value={mobileNum} onChange={(e) => setMobileNum(e.target.value)} className="formText" />
//                     </Box>

//                     <Box className="formInput">
//                         <Typography className="formInputHeader">Current Role / Background</Typography>
//                         <TextField value={currentRole} onChange={(e) => setCurrentRole(e.target.value)} className="formText" />
//                     </Box>

//                     <Box className="formInput">
//                         <Typography className="formInputHeader">Years of Experience</Typography>
//                         <Select value={experience} onChange={(e) => setExperience(e.target.value)} className="formText">
//                             <MenuItem value="Less than 1 year">Less than 1 year</MenuItem>
//                             <MenuItem value="1-3 years">1-3 years</MenuItem>
//                             <MenuItem value="3-5 years">3-5 years</MenuItem>
//                             <MenuItem value="5+ years">5+ years</MenuItem>
//                         </Select>
//                     </Box>

//                     <Box className="formInput">
//                         <Typography className="formInputHeader">Skills You Can Mentor In</Typography>
//                         <FormGroup>
//                             {skillOptions.map((skill) => (
//                                 <FormControlLabel key={skill} control={<Checkbox checked={skills.includes(skill)} onChange={handleSkillChange} value={skill} />} label={skill} />
//                             ))}
//                         </FormGroup>
//                     </Box>

//                     <Box className="formInput">
//                         <Typography className="formInputHeader">Preferred Mentorship Mode</Typography>
//                         <Select value={mentorshipMode} onChange={(e) => setMentorshipMode(e.target.value)} className="formText">
//                             <MenuItem value="Online 1:1">Online 1:1</MenuItem>
//                             <MenuItem value="Group Mentorship">Group Mentorship</MenuItem>
//                             <MenuItem value="Both">Both</MenuItem>
//                         </Select>
//                     </Box>

//                     <Box className="formInput">
//                         <Typography className="formInputHeader">Available Time Slots</Typography>
//                         <Select value={availableTime} onChange={(e) => setAvailableTime(e.target.value)} className="formText">
//                             <MenuItem value="Weekdays">Weekdays</MenuItem>
//                             <MenuItem value="Weekends">Weekends</MenuItem>
//                             <MenuItem value="Flexible">Flexible</MenuItem>
//                         </Select>
//                     </Box>

//                     <Box className="formInput">
//                         <Typography className="formInputHeader">Why Do You Want to Mentor? (Optional)</Typography>
//                         <TextField value={mentorReason} onChange={(e) => setMentorReason(e.target.value)} className="formText" multiline rows={3} />
//                     </Box>

//                     {message && <Typography className="errorText">{message}</Typography>}
//                     <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_KEY} ref={recaptchaRef} />
//                     <Button variant="contained" className="contactButton" disabled={loading}>{loading ? "Downloading..." : "Download"}</Button>
//                 </Box>
//             </Box>
//         </Box>
//     );
// }

// export default Onetoone;
