import "./last.scss";
import { Box, Button, Typography } from "@mui/material";

const Last = ({ targetJoinNow }) => {
  return (
    <Box className="wrapper-last">
      <Typography className="header-last" sx={{marginTop:"50px"}}>Join the Leading Career Coaching Network!</Typography>
      <Typography className="normal-last" sx={{ mb: "30px", marginTop: "40px !important" }}>
      Coachvita is the ultimate platform for industry professionals to mentor, earn, and make a difference. 
      </Typography>
      <Button
        onClick={() => {
          if (targetJoinNow.current) {
            targetJoinNow.current.scrollIntoView({ behavior: "smooth" });
          }
        }}
        className="btn-last"
      >
        Start Your Journey
      </Button>
    </Box>
  );
};

export default Last;
